import React from 'react';
import RegistrationLayout from './RegistrationLayout';
import AddressLink from '../components/AddressLink';
import {titleCaseKey} from '../../lib/utils';

export default (props) => {
  const {event, onEdit, onRemove, registrationState, vocabulary} = props;
  console.log(vocabulary);
  const newProps = {
    ...props,
    title: vocabulary.title + " Success",
    subTitle: "",
    content: <p className="text-center"><strong>{registrationState.data.firstName}</strong>, You are now {vocabulary.past} for the upcoming event <strong>{registrationState.data.eventName}</strong>.</p>
  };

  return (
    <RegistrationLayout {...newProps}>
      <section>
        <div className="container">
          <h3>Event Summary:</h3>
          <p>
            <iframe
              title={event.title + " Map"}
              className="map-venue"
              width="100%"
              height="175"
              src={`https://maps.google.com/maps?width=100%&height=150&hl=en&q=${encodeURIComponent(event.address.replace("\n",", "))}+(${encodeURIComponent(event.title)})&ie=UTF8&t=&z=15&iwloc=B&output=embed`}
              frameBorder="0"
              scrolling="no"
              marginHeight="0"
              marginWidth="0"
              allowFullScreen
            />
            <AddressLink address={event.address} multiline={false}/>
          </p>
          <p><strong>Date:</strong> {event.date}</p>
          <p dangerouslySetInnerHTML={{__html: event.content}} />
        </div>
      </section>
      <section>
        <div className="container">
          <h3>{vocabulary.title} Summary:</h3>
          <p>
            Below is a summary of your {vocabulary.title.toLowerCase()} information. Please print or bookmark this page for your records.
          </p>
          <div className="row">
            {Object.keys(registrationState.data).map((key) => (
              <React.Fragment key={key}>
                <div className="col-md-3"><strong>{titleCaseKey(key)}:</strong></div>
                <div className="col-md-9">{typeof registrationState.data[key] === "boolean" ? (registrationState.data[key] ? "Yes" : "No") : registrationState.data[key]}</div>
              </React.Fragment>
            ))}
          </div>
          {!!registrationState.data.id &&
            <p className="mt-5">
              <button className="btn btn-primary" onClick={() => onEdit(event.id)}>Edit Details</button>
              <button
                className="btn btn-danger"
                onClick={() => {
                  if(window.confirm("Are you sure you want to remove yourself from this event? \n(This cannot be undone!)")) {
                    onRemove(event.id || '', registrationState.data.id)
                  }
                }}>
                  Cancel Reservation
                </button>
            </p>}
        </div>
      </section>
    </RegistrationLayout>
  );
};
