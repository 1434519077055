import React from 'react';
import AppStore from '../../data';
import Layout from '../components/Layout';
import AddressLink from '../components/AddressLink';
import Rotator from './Rotator';
import History from './History';
import News from './News';
import Events from './Events';

const Home = ({title, history, news, events, rotators, aboutContent, phone, email, address}) => {
  return (
    <Layout title="Home" fixed={false}>
      <Rotator rotators={rotators} />
      <main id="main" className="home">
        <section id="about" className="section-dark-bg">
          <div className="container">
            <div className="section-header">
              <h2>About Us</h2>
            </div>
            <p className="text-center" dangerouslySetInnerHTML={{__html: aboutContent}}></p>
          </div>
        </section>
        <div>
          <History history={history} />
          <News news={news} />
          <Events events={events} />
          <section id="contact">
            <div className="container">
              <div className="section-header">
                <h2>Contact</h2>
              </div>
              <div className="row contact-info">
                <div className="col-md-4">
                  <div className="contact-address">
                    <i className="ion-ios-location-outline"></i>
                    <h3>Address</h3>
                    <address><AddressLink address={address} multiline={false} /></address>
                  </div>
                </div>
                <div className="col-md-4">
                  <div className="contact-phone">
                    <i className="ion-ios-telephone-outline"></i>
                    <h3>Phone Number</h3>
                    <p><a href={`tel:${phone.replace(/[^0-9]/gi,'')}`}>{phone}</a></p>
                  </div>
                </div>
                <div className="col-md-4">
                  <div className="contact-email">
                    <i className="ion-ios-email-outline"></i>
                    <h3>Email</h3>
                    <p><a href={`mailto:${email}`}>{email}</a></p>
                  </div>
                </div>
              </div>
            </div>
          </section>
        </div>
      </main>
    </Layout>
  );
};

export default AppStore.connect(([state, dispatch]) => ({
  title: state.app.data.info.title,
  aboutContent: state.app.data.info.aboutContent,
  rotators: state.app.data.rotators,
  history: state.app.data.history,
  news: state.app.data.news,
  events: state.app.data.events,
  phone: state.app.data.info.phone,
  email: state.app.data.info.email,
  address: state.app.data.info.address,
}))(Home);
