import React from 'react';
import Layout from '../components/Layout';
import Control from './components/Control';
import Error from '../Error';
import {AppStoreActions} from '../../data';
import {titleCaseKey} from '../../lib/utils';
import {upload} from '../../lib/api';
import history from "../../lib/history";

export default ({state, dispatch, params}) => {
  const formRef = React.useRef();
  const {sectionKey = "info"} = params;
  const sections = Object.keys(state.app.schema).map((key) => ({key, title: titleCaseKey(key)}));
  const section = state.app.data[sectionKey];
  const schema = state.app.schema[sectionKey];
  if(!state.app.schema[sectionKey]) {
    return <Error />
  }
  const onChange = (key, value) => {
    dispatch({type: AppStoreActions.app.update, key, value});
  };
  const onUpload = (e, onProgress) => {
    e.preventDefault();
    e.stopPropagation();
    upload(e, dispatch, onProgress);
  };
  return (
    <Layout title="Edit Website : Admin" fixed>
      <main id="main" className="main-page">
        <section className="section-light-bg border-bottom-white wow fadeIn">
          <div className="container text-center">
            <div className="section-header">
              <h2>Edit Website</h2>
              <p>Please select the section you want to edit below.</p>
            </div>

            <div className={"input-group w-50 d-inline-block"}>
              <select
                className="custom-select w-100 font-weight-bold"
                value={sectionKey}
                onChange={(e) => {
                    history.push(`/admin/edit/${e.target.value}`);
                }}
              >
                {sections.map((o,index) => <option key={index} value={o.key}>{o.title}</option>)}
              </select>
            </div>
          </div>
        </section>
        <section className="wow fadeIn">
          <div className="container">
            <form ref={formRef} onSubmit={(e) => e.preventDefault()} noValidate>
              <Control
                shape={schema}
                data={section}
                onChange={onChange}
                name={sectionKey}
                title={titleCaseKey(sectionKey)}
                onUpload={onUpload}
              />
            </form>
          </div>
        </section>
      </main>
    </Layout>
  );
};
