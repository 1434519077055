import React from 'react';
import Layout from '../components/Layout';
import SmartLink from '../components/SmartLink';
import {idFromString} from '../../lib/utils';

export default ({state, dispatch}) => {
  const events = state.app.data.events.filter((e) => e.registration && e.registration.startDate);

  return (
    <Layout title="Download Event Registration" fixed>
      <main id="main" className="main-page">
        <section className="section-light-bg border-bottom-white wow fadeIn">
          <div className="container">
            <div className="section-header">
              <h2>Download Event Registration</h2>
              <p>Please select which event registration you want to download below.</p>
            </div>
          </div>
        </section>
        <section className="wow fadeIn">
          <div className="container">
            <table>
              <thead>
                <tr>
                  <th>Event</th>
                  <th className="text-center">Reg. Start Date</th>
                  <th className="text-center" width="5%">Download</th>
                </tr>
              </thead>
              <tbody>
                {events.length > 0 && events.map((e, index) => (
                  <tr key={index}>
                    <td>
                      <SmartLink to={`/registration/${idFromString(e.title)}`} >{e.title}</SmartLink>
                    </td>
                    <td className="text-center">{e.registration.startDate}</td>
                    <td className="text-center">
                      {Date.parse(e.registration.startDate) < new Date().getTime() && <a
                        href={`/api/admin/registration/${idFromString(e.title)}`}
                        className="btn mb-1 btn-primary btn-small"
                      >
                        Download CSV
                      </a>}
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        </section>
      </main>
    </Layout>
  );
};
