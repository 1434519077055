import React from "react";

export default ({name, value, shape, onChange}) => {
  React.useEffect(() => {
    var editor = window.ace.edit(name);
    editor.setValue(value);
    editor.navigateFileStart();
    //editor.setTheme("ace/theme/monokai");
    editor.session.setMode("ace/mode/html");
    editor.setOptions({
      minLines: shape === "editor-small" ? 3 : 25,
      maxLines: shape === "EditorSmall" ? Infinity : 25,
      wrap: true,
      indentedSoftWrap: false,
      behavioursEnabled: true,
      showLineNumbers: shape !== "editor-small",
      useWorker: false
    });
    document.getElementById(name).style.fontSize='14px';
    editor.session.on('change', function(delta) {
        // delta.start, delta.end, delta.lines, delta.action
        onChange(name, editor.getValue());
    });
    return () => {
      editor.destroy();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  },[]);
  return <div id={name} className={shape} />
}
