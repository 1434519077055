import React from "react";

export default ({address, multiline = true}) => {
  return (
    <a
      href={`https://www.google.com/maps/place/${address.replace(" ","+").replace("\n", ",")}`}
      dangerouslySetInnerHTML={{__html: address.replace("\n", multiline ? "<br />" : ", ")}}
      target="_blank"
      rel="noopener noreferrer"
    />
  );
};
