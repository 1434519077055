import React from 'react';
import Layout from '../components/Layout';
import SmartLink from '../components/SmartLink';
import AppStore from '../../data';
import {useParams} from 'react-router-dom';
import {basename,getEmptySchema} from '../../lib/utils';
import {contactUs} from '../../lib/api';
import Control from '../admin/components/Control';
import {getFinalData, validateForm, resetFormValidation, titleToCamelCase} from '../../lib/utils';

const Contact = ({campaigns, title}) => {
  const params = useParams();
  const [thankyou, setThankyou] = React.useState(false);
  const formRef = React.useRef();
  const campaign = campaigns.find(it => it.id === params.campaignId) || {};
  const contact = {
    id: "default",
    title: "Contact Us",
    subTitle: "Please fill out the form below and we will get back to you as soon as possible.",
    content: "",
    defaultMessage: "",
    files: [],
    fields: [],
    ...campaign
  };
  if(!contact.files.length) {
    contact.files = ["/img/mail.jpg"];
  }
  const files = contact.files;
  const hasLeftSide = !!files.length;

  const schema = {
    fullName: "",
    emailAddress: "email",
    phoneNumber: "phone",
    message: "textarea",
  };
  contact.fields.forEach(it => {
    schema[titleToCamelCase(it.title)] = it.type;
  });
  const initialState = getEmptySchema(schema);
  initialState.campaign = contact.id;
  initialState.campaignTitle = contact.title;
  initialState.message = contact.defaultMessage;
  const [state, setState] = React.useState(initialState);
  const onChange = (key, value) => {
    setState({...state, [key]: value});
  }

  if(thankyou) {
    return (
      <Layout title={contact.title} fixed>
        <main id="main" className="main-page">
          <section className="section-light-bg border-bottom-white wow fadeIn">
            <div className="container">
              <div className="section-header">
                <h2>Thank you for contacting us!</h2>
                <p>We will be getting back to you as soon as possible.</p>
              </div>

              <ul className="nav nav-tabs" role="tablist">
                <li className="nav-item">
                  <SmartLink
                    to={'/'}
                    className={`nav-link`}
                  >
                    Back to Home
                  </SmartLink>
                </li>
              </ul>
            </div>
          </section>
        </main>
      </Layout>
    );
  }

  return (
    <Layout title={contact.title} fixed>
      <main id="main" className="main-page">
        <section className="section-light-bg border-bottom-white wow fadeIn">
          <div className="container">
            <div className="section-header">
              <h2>{contact.title}</h2>
              <p>{contact.subTitle}</p>
            </div>
          </div>
        </section>
        <section className="wow fadeIn">
          <div className="container">
            <div className="row">
              {hasLeftSide && <div className={"col-md-3 mb-4"}>
                <div className="row no-gutters">
                  {files.filter((f) => f.indexOf(".jpg") !== -1).map((path, i) => (
                    <div key={i} className={i === 0 ? "col-12" : "col-4"}>
                      <a href={path} target="_blank" rel="noopener noreferrer">
                        <img src={path} alt={basename(path)} className="img-fluid thumbnail" />
                      </a>
                    </div>
                  ))}
                </div>
              </div>}
              <div className={hasLeftSide ? "col-md-9" : "col-md-12"}>
                {!!contact.content && <div className="mb-4">
                  <h3>{contact.title}</h3>
                  <div dangerouslySetInnerHTML={{__html: contact.content}} />
                </div>}
                <div className="light-bg p-5 rounded">
                  <form ref={formRef} onSubmit={(e) => e.preventDefault()} noValidate>
                    <input type="hidden" name="campaign" id="campaign" value={state.campaign} />
                    <input type="hidden" name="campaignTitle" id="campaignTitle" value={state.campaignTitle} />
                    <Control
                      shape={schema}
                      data={state}
                      onChange={onChange}
                      title={""}
                      simple={true}
                      required={true}
                    />
                    <button
                      type="form-control"
                      className="btn btn-primary"
                      onClick={() => {
                        if(validateForm(formRef.current)) {
                          // ok
                          contactUs(getFinalData(state, formRef.current))
                            .then((response) => {
                              setThankyou(true)
                            });
                        }}}
                    >
                      Submit
                    </button>
                    <button
                      type="form-control"
                      className="btn btn-default"
                      onClick={() => {setState(initialState); resetFormValidation(formRef.current);}}
                    >
                      Reset
                    </button>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </section>
      </main>
    </Layout>
  );
};


export default AppStore.connect(([state, dispatch]) => ({
  title: state.app.data.info.title,
  campaigns: state.app.data.contactCampaign || [],
}))(Contact);
