import React from 'react';
import RegistrationLayout from './RegistrationLayout';
import AddressLink from '../components/AddressLink';
import {paragraphDate, getFinalData, validateForm, validateField, resetFormValidation} from '../../lib/utils';
import moment from "moment";

const states = [
  {value: "AL", title: "Alabama"},
  {value: "AK", title: "Alaska"},
  {value: "AZ", title: "Arizona"},
  {value: "AR", title: "Arkansas"},
  {value: "CA", title: "California"},
  {value: "CO", title: "Colorado"},
  {value: "CT", title: "Connecticut"},
  {value: "DE", title: "Delaware"},
  {value: "DC", title: "District Of Columbia"},
  {value: "FL", title: "Florida"},
  {value: "GA", title: "Georgia"},
  {value: "HI", title: "Hawaii"},
  {value: "ID", title: "Idaho"},
  {value: "IL", title: "Illinois"},
  {value: "IN", title: "Indiana"},
  {value: "IA", title: "Iowa"},
  {value: "KS", title: "Kansas"},
  {value: "KY", title: "Kentucky"},
  {value: "LA", title: "Louisiana"},
  {value: "ME", title: "Maine"},
  {value: "MD", title: "Maryland"},
  {value: "MA", title: "Massachusetts"},
  {value: "MI", title: "Michigan"},
  {value: "MN", title: "Minnesota"},
  {value: "MS", title: "Mississippi"},
  {value: "MO", title: "Missouri"},
  {value: "MT", title: "Montana"},
  {value: "NE", title: "Nebraska"},
  {value: "NV", title: "Nevada"},
  {value: "NH", title: "New Hampshire"},
  {value: "NJ", title: "New Jersey"},
  {value: "NM", title: "New Mexico"},
  {value: "NY", title: "New York"},
  {value: "NC", title: "North Carolina"},
  {value: "ND", title: "North Dakota"},
  {value: "OH", title: "Ohio"},
  {value: "OK", title: "Oklahoma"},
  {value: "OR", title: "Oregon"},
  {value: "PA", title: "Pennsylvania"},
  {value: "RI", title: "Rhode Island"},
  {value: "SC", title: "South Carolina"},
  {value: "SD", title: "South Dakota"},
  {value: "TN", title: "Tennessee"},
  {value: "TX", title: "Texas"},
  {value: "UT", title: "Utah"},
  {value: "VT", title: "Vermont"},
  {value: "VA", title: "Virginia"},
  {value: "WA", title: "Washington"},
  {value: "WV", title: "West Virginia"},
  {value: "WI", title: "Wisconsin"},
  {value: "WY", title: "Wyoming"},
];
const belts = [
  {title: "White Belt", value: 1},
  {title: "White Belt (Yellow Stripe)", value: 1.5},
  {title: "Yellow Belt", value: 2},
  {title: "Yellow Belt (Blue Stripe)", value: 2.5},
  {title: "Blue Belt", value: 3},
  {title: "Blue Belt (Purple Stripe)", value: 3.5},
  {title: "Purple Belt", value: 4},
  {title: "Purple Belt (Brown Stripe)", value: 4.5},
  {title: "Brown Belt", value: 5},
  {title: "Brown Belt (Black Stripe)", value: 5.5},
  {title: "1st Degree Black Belt", value: 6},
  {title: "Advanced Black Belt", value: 100},
];
const instructors = [
  {title: "Grand Master Knife"},
  {title: "Grand Master Tesdal"},
  {title: "Laurel Coombs"},
  {title: "Masters Brown"},
  {title: "Master Ahn"},
  {title: "Masters Buren"},
  {title: "Master Bossen"},
  {title: "Master Carothers"},
  {title: "Grand Master French"},
  {title: "Master Hawes"},
  {title: "Grand Master Henkel"},
  {title: "Master Nancy Henkel"},
  {title: "Master Hull"},
  {title: "Master Secord"},
  {title: "Master Dacuan"},
  {title: "Master Tindall"},
  {title: "Master Wahlrab"},
  {title: "Master Williams"},
  {title: "Mr. Toledo"},
  {title: "Mr. Ayala"}, 
  {title: "Ms. Flood"}, 
  {title: "Other"},
];

const FormBody = ({event, registrationState, onChange, onSubmit, onReset, email, phone, schools, simple = false}) => {
  const formRef = React.useRef();
  const data = registrationState.data;
  const now = new Date();
  const formData = {
    firstName: "",
    lastName: "",
    email: "",
    phone: "",
    address: "",
    city: "",
    state: "OR",
    zip: "",
    birthDate: "",
    gender: "",
    emergencyName: "",
    emergencyPhone: "",
    beltName: "",
    beltValue: "",
    feet: "",
    inches: "",
    weight: "",
    instructor: "",
    instructorOther: "",
    forms: true,
    fighting: true,
    breaking: false,
    teamName: "",
    guardianName: "",
    termsConditions: false,
    ...data,
    eventId: event.id,
    eventName: event.title,
  }
  const isExisting = !!data.id && data.eventId === event.id;
  let underage = true;
  const dobParts = formData.birthDate.split("/");
  if(formData.birthDate && formData.birthDate.split("/").length === 3) {
    underage = now.getFullYear() - parseInt(dobParts[2], 10) < 18;
  }
  return (
    <form ref={formRef} onSubmit={(e) => e.preventDefault()} noValidate>
      <section>
        <div className="container">
            <h3>{isExisting ? "Edit - " : ""}Contact Information</h3>
            <input type="hidden" name="eventName" value={formData.eventName} />
            <div className="row">
              <div className="col-md-6">
                <div className="form-group">
                  <label className="required" htmlFor="firstName">First Name</label>
                  <input className="form-control" required="required" type="input" id="firstName" name="firstName" onChange={onChange} onBlur={validateField} value={formData.firstName} />
                </div>
                <div className="form-group">
                  <label className="required" htmlFor="lastName">Last Name</label>
                  <input className="form-control" required="required" type="input" id="lastName" name="lastName" onChange={onChange} onBlur={validateField} value={formData.lastName} />
                </div>
                <div className="form-group">
                  <label className="required" htmlFor="email">Email</label>
                  <input className="form-control" required="required" type="input" id="email" name="email" data-type="email" onChange={onChange} onBlur={validateField} value={formData.email} />
                </div>
                <div className="form-group">
                  <label className="required" htmlFor="phone">Phone <small>ex. (555) 555-5555</small></label>
                  <input className="form-control" required="required" type="input" id="phone" name="phone" placeholder="(555) 555-5555" data-type="phone" onChange={onChange} onBlur={validateField} value={formData.phone} />
                </div>
                {!simple && <div className="form-group">
                  <label className="required" htmlFor="address">Address:</label>
                  <input className="form-control" required="required" type="input" id="address" name="address" placeholder="Street" onChange={onChange} onBlur={validateField} value={formData.address} />
                </div>}
                {!simple && <div className="form-group">
                  <input className="form-control" required="required" type="input" id="city" name="city" placeholder="City" onChange={onChange} onBlur={validateField} value={formData.city} />
                </div>}
                {!simple && <div className="row">
                  <div className="col-md-6">
                    <div className="form-group">
                      <select className="form-control" required="required" name="state" id="state" onChange={onChange} onBlur={validateField} value={formData.state}>
                        <option value="">- Select State -</option>
                        {states.map((state, index) => <option key={index} value={state.value}>{state.title}</option>)}
                      </select>
                    </div>
                  </div>
                  <div className="col-md-6">
                    <div className="form-group">
                      <input className="form-control" required="required" type="input" id="zip" name="zip" placeholder="Zip" onChange={onChange} onBlur={validateField} value={formData.zip} />
                    </div>
                  </div>
                </div>}
              </div>
              <div className="col-md-6">
                <div className="form-group">
                  <label className="required" htmlFor="birthDate">Birth Date <small>(mm/dd/yyyy)</small></label>
                  <input className="form-control" required="required" type="input" id="birthDate" name="birthDate" placeholder="mm/dd/yyyy" data-type="date" onChange={onChange} onBlur={validateField} value={formData.birthDate} />
                </div>
                {!simple && <div className="form-group">
                  <label className="required" htmlFor="gender">Gender</label>
                  <select className="form-control" required="required" name="gender" id="gender" onChange={onChange} onBlur={validateField} value={formData.gender}>
                      <option value="">- Select Gender -</option>
                      <option value="male">Male</option>
                      <option value="female">Female</option>
                  </select>
                  <small>
                    * Please note that the Male/Female designation is in no way intended as a comment on or expression of anyone's personal gender identity. 
                    These are simply compitition category designations for the Open and Senior devisions (Male/Female competition categories are mixed for Juniors and below).
                  </small>
                </div>}
                {!simple && <div className="form-group">
                  <label className="required" htmlFor="emergencyName">Emergency Contact Name</label>
                  <input className="form-control" required="required" type="input" id="emergencyName" name="emergencyName" onChange={onChange} onBlur={validateField} value={formData.emergencyName} />
                </div>}
                {!simple && <div className="form-group">
                  <label className="required" htmlFor="emergencyPhone">Emergency Contact Phone <small>ex. (555) 555-5555</small></label>
                  <input className="form-control" required="required" type="input" id="emergencyPhone" name="emergencyPhone" placeholder="(555) 555-5555" data-type="phone" onChange={onChange} onBlur={validateField} value={formData.emergencyPhone} />
                </div>}
              </div>
            </div>
          </div>
        </section>
        {!simple && <section>
          <div className="container">
            <h3>{isExisting ? "Edit - " : ""}Tell us about yourself and instructor?</h3>
            <div className="row">
              <div className="col-md-6">
                <div className="form-group">
                  <label className="required" htmlFor="beltName">Belt Rank On Event Date</label>
                  <select
                    className="form-control"
                    required="required"
                    name="beltName"
                    id="beltName"
                    onChange={(e) => {
                      onChange(e);
                      const value = belts.find((b) => b.title === e.target.value).value;
                      onChange({target: {name: "beltValue", value: value}});
                      onChange({target: {name: "breaking", value: false}});
                    }}
                    onBlur={validateField}
                    value={formData.beltName}
                  >
                    <option value="">- Select Belt -</option>
                    {belts.map((state, index) => <option key={index} value={state.title}>{state.title}</option>)}
                  </select>
                  <input className="form-control" type="hidden" name="beltValue" id="beltValue" value={formData.beltValue} />
                </div>
                <label className="required" htmlFor="feet">Height</label>
                <div className="row">
                  <div className="col-md-6">
                    <div className="form-group">
                      <input className="form-control" required="required" type="number" id="feet" name="feet" placeholder="Feet" onChange={onChange} onBlur={validateField} value={formData.feet} />
                    </div>
                  </div>
                  <div className="col-md-6">
                    <div className="form-group">
                      <input className="form-control" required="required" type="number" id="inches" name="inches" placeholder="Inches" onChange={onChange} onBlur={validateField} value={formData.inches} />
                    </div>
                  </div>
                </div>
                <div className="form-group">
                  <label className="required" htmlFor="weight">Weight <small>(lbs)</small></label>
                  <input className="form-control" required="required" type="number" id="weight" name="weight" placeholder="lbs" onChange={onChange} onBlur={validateField} value={formData.weight} />
                </div>
              </div>
              <div className="col-md-6">
                <div className="form-group">
                  <label className="required" htmlFor="instructor">Primary Instructor</label>
                  <select className="form-control" required="required" name="instructor" id="instructor" onChange={onChange} onBlur={validateField} value={formData.instructor}>
                    <option value="">- Select Instructor -</option>
                    {instructors.map((state, index) => <option key={index} value={state.title}>{state.title}</option>)}
                  </select>
                </div>
                {formData.instructor === "Other" && <div className="form-group">
                  <label className="required" htmlFor="instructorOther">If other, list instructor.</label>
                  <input className="form-control" required="required" type="input" id="instructorOther" name="instructorOther" placeholder="" onChange={onChange} onBlur={validateField} value={formData.instructorOther} />
                </div>}
                <p><small>We collect your age, gender, rank, height and weight information to make a better overall experience when it comes to pairing opponents.</small></p>
              </div>
            </div>
          </div>
        </section>}
        {!simple && <section>
          <div className="container">
            <h3>{isExisting ? "Edit - " : ""}What activities do you want to partake in?</h3>
            <div className="row">
              <div className="col-md-6">
                <div className="form-group form-check">
                  <input className="form-check-input" type="checkbox" id="forms" name="forms" onChange={onChange} onBlur={validateField} checked={formData.forms} />
                  <label className="form-check-label" htmlFor="forms">
                    Forms
                  </label>
                </div>
                <div className="form-group form-check">
                  <input className="form-check-input" type="checkbox" id="fighting" name="fighting" onChange={onChange} onBlur={validateField} checked={formData.fighting} />
                  <label className="form-check-label" htmlFor="fighting">
                    Fighting
                  </label>
                </div>
                <div className="form-group form-check">
                  <input disabled={formData.beltValue < 4.5} className="form-check-input" type="checkbox" id="breaking" name="breaking" onChange={onChange} onBlur={validateField} checked={formData.breaking} />
                  <label className="form-check-label" htmlFor="breaking">
                    Board Breaking <small>(Brown Stripe and Above.)</small>
                  </label>
                </div>
              </div>
              <div className="col-md-6">
                <div className="form-group">
                  <label className="" htmlFor="teamName">Team Name <small>(Only team captain fills this field.)</small></label>
                  <input className="form-control" type="input" id="teamName" name="teamName" onChange={onChange} onBlur={validateField} value={formData.teamName} />
                </div>
              </div>
            </div>
        </div>
      </section>}

      <section>
        <div className="container">
          <h3>{isExisting ? "Edit - " : ""}Disclaimer Agreement and Submit</h3>
          <p>
            {event.type === "tournament" && (
              <React.Fragment>
                <a href={`/img/tournament-rules.jpg`} target="_blank" rel="noopener noreferrer">Event Rules</a>
                <br /><br />
              </React.Fragment>
            )}
            By submitting this form, I do hereby voluntarily submit my application for participation in the upcoming event sponsored by Kim's Taekwon-do, LLC. I agree to waive all claims against any persons connected with this event for any injuries that I may sustain and assume full responsibility for all my actions in connection with said event. I fully understand that any medical treatment given to me will be First Aid treatment.
          </p>
          <p>
            I further agree that any pictures taken of or by me in connection with this event can be used by the event director for publicity or promotion without compensation at this time or any other time.
          </p>
          <div className="row">
            <div className="col-md-6">
              {underage && <div className="form-group">
                <label className="required" htmlFor="guardianName">Parental Guardian <small>(For participants under 18)</small></label>
                <input className="form-control" required="required" type="input" id="guardianName" name="guardianName" onChange={onChange} onBlur={validateField} value={formData.guardianName} />
              </div>}
              <div className="form-group form-check">
                <input className={`form-check-input ${formData.termsConditions ? "is-valid" : ""}`} required="required" type="checkbox" id="termsConditions" name="termsConditions" onChange={onChange} checked={formData.termsConditions} />
                <label className="form-check-label required" htmlFor="termsConditions">
                  I agree to the terms and conditions.
                </label>
              </div>
            </div>
            <div className="col-md-6">
            </div>
          </div>
          <button
            type="form-control"
            className="btn btn-primary"
            onClick={() => {
              if(validateForm(formRef.current)) {
                onSubmit(event.id, getFinalData(formData, formRef.current));
              }}}
          >
            Submit
          </button>
          <button
            type="form-control"
            className="btn btn-default"
            onClick={(e) => {onReset(e); resetFormValidation(formRef.current);}}
          >
            Reset
          </button>
        </div>
      </section>
    </form>
  );
};

export default (props) => {
  const {event, eventCount, email, phone, vocabulary} = props;
  const newProps = { ...props };

  const now = moment().unix();
  const start = moment(event.registration.startDate).unix();
  const end = moment(event.registration.endDate).add(1, 'day').unix();

  let isClosed = false;
  let isOpen = false;
  let spotsRemaining = event.registration.max - eventCount;
  if(now > start) {
    // now is > start
    isOpen = true;
  }
  if(event.registration.max <= eventCount || now > end) {
    isClosed = true;
    spotsRemaining = 0;
  }

  newProps.title = `${event.title} ${vocabulary.title}${isClosed ? " - CLOSED" : ""}`;
  newProps.subTitle = event.registration.startDate ? (
      isClosed ? <span className="text-danger">{vocabulary.title} has ended!</span> : <span>{vocabulary.title} begins {event.registration.startDate} - {event.registration.endDate}</span>
    ) : "";
  newProps.simple = event && event.registration ? event.registration.type === "simple" : false;

  if(!newProps.simple) {
    newProps.content = (
      <React.Fragment>
        <p>
          Fill out the form below to {vocabulary.title.toLowerCase()} for the {event.title} in Portland, Oregon. The event will be held on {paragraphDate(event.date)} at <AddressLink address={event.address} multiline={false}/>. Make sure to register using the belt rank you will be on {paragraphDate(event.date, true)}. Upon completing and submitting the {vocabulary.title.toLowerCase()} form, please print the "{vocabulary.title} Success" page for your records. This will be your proof of {vocabulary.title.toLowerCase()}. If you do not get this page or would like to cancel your {vocabulary.title.toLowerCase()}, please send an email to <a href={`mailto:${email}`}>{email}</a> or call us at <strong>{phone}</strong>.
        </p>
        <p>
          This event will include Forms (Hyungs), Sparring, Breaking (limited to brown-stripes and above, Peewee's-l board and Juniors-2 board setups), and Team Competitions (brown belts through 4th Degree black belt). <strong>All teams will need to pre-register. Only the Team Captain will register the team name.</strong> Medals will be awarded for 1st, 2nd, and 3rd places. Check-in and at the door {vocabulary.title.toLowerCase()} will begin at 9:30am. The event will begin at 10:30am, and we anticipate finishing by 5pm.
        </p>
        <p>
          {vocabulary.title} fee is ${event.registration.preRegistrationFee} if you Pre-Registered. This fee can be paid anytime between now and the date of the event. By filling out the form below you are committing to attend and compete in the event but you are not required to pay the {vocabulary.title.toLowerCase()} fee at this time. You must cancel your {vocabulary.title.toLowerCase()} at least 48 hours prior to the event start time to not be responsible for the {vocabulary.title.toLowerCase()} fee. <strong>{vocabulary.title} closes when maximum attendance reaches {event.registration.max} contestants, or {paragraphDate(event.registration.endDate)} whichever comes first.</strong>
        </p>
        <p>
          {vocabulary.title} fee is ${event.registration.fee} after {paragraphDate(event.registration.endDate)} (or at the door on the day of the event). The spectator admission fee is ${event.registration.spectatorFee} and admission for children 10 & under is free.
        </p>
        <p className="content" dangerouslySetInnerHTML={{ __html: event.registration.content }} />
        <p>
          Fields marked with an asterisk (*) must be entered.
        </p>
        <p>
          Fields marked with an asterisk (*) must be entered.
        </p>
        <p>
          We will not give out your personal information to anyone.
        </p>
        <p>
          This {vocabulary.title.toLowerCase()} form is for COMPETITORS ONLY.
        </p>
        {!!event.registration.max && <p>
          There are <strong>{spotsRemaining}</strong> out of the <strong>{event.registration.max}</strong> spots remaining.
        </p>}
      </React.Fragment>
    )
  }
  else {
    newProps.content = (
      <React.Fragment>
        <p>
          Fill out the form below to {vocabulary.title.toLowerCase()} for {event.title}. The event will be held on {paragraphDate(event.date)} at <AddressLink address={event.address} multiline={false}/>. Upon completing and submitting the {vocabulary.title.toLowerCase()} form, please print the "{vocabulary.title} Success" page for your records. This will be your proof of {vocabulary.title.toLowerCase()}. If you do not get this page or would like to cancel your {vocabulary.title.toLowerCase()}, please send an email to <a href={`mailto:${email}`}>{email}</a> or call us at <strong>{phone}</strong>.
        </p>
        {!!event.registration.preRegistrationFee && <p>
          {vocabulary.title} fee is ${event.registration.preRegistrationFee} if you Pre-Registered. This fee can be paid anytime between now and the date of the event. By filling out the form below you are committing to attend and compete in the event but you are not required to pay the {vocabulary.title.toLowerCase()} fee at this time. You must cancel your {vocabulary.title.toLowerCase()} at least 48 hours prior to the event start time to not be responsible for the {vocabulary.title.toLowerCase()} fee. <strong>{vocabulary.title} closes when maximum attendance reaches {event.registration.max} contestants, or {paragraphDate(event.registration.endDate)} whichever comes first.</strong>
        </p>}
        {(!!event.registration.fee || !!event.registration.spectatorFee) && <p>
          {!!event.registration.fee && <React.Fragment>{vocabulary.title} fee is ${event.registration.fee} after {paragraphDate(event.registration.endDate)} (on the day of the event).</React.Fragment>}
          {!!event.registration.spectatorFee && <React.Fragment>The spectator admission fee is ${event.registration.spectatorFee} and admission for children 10 & under is free.</React.Fragment>}
        </p>}
        <p className="content" dangerouslySetInnerHTML={{__html: event.registration.content}} />
        <p>
          Fields marked with an asterisk (*) must be entered.
        </p>
        <p>
          We will not give out your personal information to anyone.
        </p>
        {!!event.registration.max && <p className={isClosed ? "text-danger" : ""}>
          There are <strong>{spotsRemaining}</strong> out of the <strong>{event.registration.max}</strong> spots remaining.
        </p>}
      </React.Fragment>
    );
  }
  // TODO: handle closed event here...

  return (
    <RegistrationLayout {...newProps}>
      {(!isClosed && isOpen) && <FormBody {...newProps} />}
      {isClosed && <section>
          <div className="container text-center">
            <h3>{vocabulary.title} is now closed</h3>
            <p></p>
          </div>
        </section>}
      {!isOpen &&  <section>
          <div className="container text-center">
            <h3>{vocabulary.title} is not open at this time.</h3>
            <p></p>
          </div>
        </section>}
    </RegistrationLayout>
  );
};
