import React from 'react';
import Layout from '../components/Layout';
import AddressLink from '../components/AddressLink';
import SmartLink from '../components/SmartLink';
import {FORM_VOCABULARY} from '../registration/Registration';
import AppStore from '../../data';
import {
  getMonth,
  getYear,
  idFromString,
  basename
} from '../../lib/utils';

const filters = [
  {key: "tournament", title: "Tournaments", icon: <i className="fa fa-trophy" />, filter: (f) => f.type === "tournament"},
  {key: "promotion", title: "Promotions", icon: <i className="fa fa-star" />, filter: (f) => f.type === "promotion"},
  {key: "other", title: "Other", icon: <i className="fa fa-calendar" />, filter: (f) => f.type === "other" || !eventTypeKeys.includes(f.type)},
  {key: "all", title: "All Events", icon: null, filter: () => true},
];

const eventTypeKeys = filters.map(t => t.key);

export const getTypeInfo = (key) => {
  return filters.find(t => t.key === key) || filters[2];
}
const monthsNames = (year) => [
  "January",
  "February",
  "March",
  "April",
  "May",
  "June",
  "July",
  "August",
  "September",
  "October",
  "November",
  "December",
].map((title, month) => ({month, title, year}));
export const getEventsByMonth = (events) => {
  const now = new Date();
  const months = [
    ...monthsNames(now.getUTCFullYear()),
    ...monthsNames(now.getUTCFullYear()+1)]
    .map((month, key) => ({
      key,
      ...month,
      events: events.filter(e => getMonth(e.date) === month.month && getYear(e.date) === month.year)
    }))
    .filter(m => m.events.length && ((m.year >= now.getUTCFullYear() && m.month >= now.getMonth()) || m.year > now.getUTCFullYear() ) );
  return months;
}

const hasRegistration = (r) => {
  if(!r || !r.startDate || !r.startDate) return false;
  return true;
}

const canRegister = (r) => {
  if(!r) return false;
  const now = new Date().getTime();
  return now >= Date.parse(r.startDate) && now <= Date.parse(r.endDate);
}

const Events = ({events, location}) => {
  const [filterBy, setFilterBy] = React.useState("all");
  const filter = filters.find((f) => f.key === filterBy);
  const months = getEventsByMonth(
    events.filter(filter.filter)
  );
  return (
    <Layout title="Events" fixed>
      <main id="main" className="main-page">
        <section className="section-light-bg border-bottom-white wow fadeIn">
          <div className="container">
            <div className="section-header">
              <h2>Events</h2>
              <p>Here is our upcoming events schedule</p>
            </div>

            <ul className="nav nav-tabs" role="tablist">
              {filters.map((f) => {
                const now = new Date();
                const length = events.filter((e) => f.filter(e) && ((getYear(e.date) >= now.getUTCFullYear() && getMonth(e.date) >= now.getMonth()) || getYear(e.date) > now.getUTCFullYear() )).length;
                if(!length) return null;
                return (
                  <li key={f.key} className="nav-item">
                    <SmartLink
                      to={'/events'}
                      className={`nav-link ${f.key === filterBy ? "active" : ""}`}
                      onClick={() => setFilterBy(f.key)}
                      role="tab" data-toggle="tab"
                    >
                      {f.icon} {f.title} ({length})
                    </SmartLink>
                  </li>
                );
              })}
            </ul>
          </div>
        </section>
        <div key={filterBy} className="wow fadeIn">
          {months.map((month, index) => (
            <section key={month.key}>
              <div className="container">
                <div className="month">
                  <div className="schedule-item">
                    <div className="row ">
                      <div className="col-md-12">
                        <h3 className="">{month.title} {month.year}</h3>
                      </div>
                    </div>
                  </div>
                  {month.events.map((event, index) => {
                    const id = idFromString(event.title);
                    const t = getTypeInfo(event.type);
                    const expanded = location.hash ? location.hash.replace('#', '') === id : false;
                    const vocabulary = event.registration ? FORM_VOCABULARY[event.registration.type || "simple"] : {};
                    const files = event.files || [];
                    return (
                      <div key={index} id={idFromString(event.title)} className=" schedule-item">
                        <div className="row">
                          <div className="col-md-4">
                            {!expanded && <p><time>{event.date}</time></p>}
                            {expanded && (
                              <div className="row nogutters">{
                                files.filter((f) => f.indexOf(".jpg") !== -1).map((img, i) => (
                                  <div key={i} className={i === 0 ? "col-12" : "col-4"}>
                                    <a href={img} target="_blank" rel="noopener noreferrer">
                                      <img src={img} alt={basename(img)} className="img-fluid thumbnail" />
                                    </a>
                                  </div>
                                ))}
                              </div>
                            )}
                            {expanded && event.address && <iframe
                              title={event.title + " Map"}
                              className="map-venue"
                              width="100%"
                              height="175"
                              src={`https://maps.google.com/maps?width=100%&height=150&hl=en&q=${encodeURIComponent(event.address.replace("\n",", "))}+(${encodeURIComponent(event.title)})&ie=UTF8&t=&z=15&iwloc=B&output=embed`}
                              frameBorder="0"
                              scrolling="no"
                              marginHeight="0"
                              marginWidth="0"
                              allowFullScreen
                            />}
                          </div>
                          <div className="col-md-8">
                            <h4><SmartLink to={`/events/#${id}`}>{t.icon} {event.title}</SmartLink></h4>
                            {expanded && <div>
                              <p><time>{event.date}</time></p>
                              <p><AddressLink address={event.address} /></p>
                              <p className="content" dangerouslySetInnerHTML={{__html: event.content}} />
                              {event.link && <SmartLink to={event.link} className="">Learn More</SmartLink>}
                            </div>}
                            {!expanded && <SmartLink to={`/events/#${id}`} className="btn btn-primary btn-small">Show Event Details</SmartLink>}
                            {canRegister(event.registration) && <SmartLink to={`/registration/${id}`} className="btn btn-default btn-small">Continue to {vocabulary.title}</SmartLink>}
                            {hasRegistration(event.registration) && <p><small>({vocabulary.title} is required to attend this event.)</small></p>}
                          </div>
                        </div>
                      </div>
                    );
                  })}
                </div>
              </div>
            </section>
          ))}
        </div>
      </main>
    </Layout>
  );
};


export default AppStore.connect(([state, dispatch]) => ({
  events: state.app.data.events,
}))(Events);
