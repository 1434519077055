import React from 'react';
import Layout from '../components/Layout';
import {getFinalData, validateForm, validateField, resetFormValidation} from '../../lib/utils';
import {updateProfile} from '../../lib/api';

export default ({state, dispatch}) => {
  const user = state.admin.data;
  const formRef = React.useRef();
  const [formData, setFormData] = React.useState({email: user.email, old_password: "", password: ""});
  const onChange = (e) => {setFormData({...formData, [e.target.name]: e.target.value})};
  React.useEffect(() => {
    setFormData({email: user.email, old_password: "", password: ""});
  }, [user.email]);
  return (
    <Layout title="Profile" fixed>
      <main id="main" className="main-page">
        <section className="section-light-bg border-bottom-white wow fadeIn">
          <div className="container">
            <div className="section-header">
              <h2>Profile</h2>
              <p>You can edit your profile below.</p>
            </div>
          </div>
        </section>
        <section className="wow fadeIn">
          <div className="container">
            <form ref={formRef} onSubmit={(e) => e.preventDefault()} noValidate>
              <div className="form-group">
                <label>Name</label>
                <div>{user.name}</div>
              </div>
              <div className="form-group">
                <label className="required" htmlFor="old_password">Current Password (must be provided to make any changes)</label>
                <input className="form-control" required="required" type="password" id="old_password" name="old_password" data-type="old_password" onChange={onChange} onBlur={validateField} value={formData.old_password} />
              </div>
              <div className="form-group">
                <label className="required" htmlFor="email">Email</label>
                <input className="form-control" required="required" type="input" id="email" name="email" data-type="email" onChange={onChange} onBlur={validateField} value={formData.email} />
              </div>
              <div className="form-group">
                <label htmlFor="password">New Password (Leave blank if not changing)</label>
                <input className="form-control" type="password" id="password" name="password" onChange={onChange} onBlur={validateField} value={formData.password} />
              </div>
              <button
                type="form-control"
                className="btn btn-primary"
                onClick={() => {
                  if(validateForm(formRef.current)) {
                    updateProfile(getFinalData(formData, formRef.current), dispatch);
                  }}}
              >
                Save
              </button>
              <button
                type="form-control"
                className="btn btn-default"
                onClick={() => {setFormData({email: user.email, old_password: "", password: ""}); resetFormValidation(formRef.current);}}
              >
                Reset
              </button>
            </form>
          </div>
        </section>
      </main>
    </Layout>
  );
};
