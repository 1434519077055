import React from 'react';
import Layout from '../components/Layout';
import SmartLink from '../components/SmartLink';
import AppStore from '../../data';

const SiteTree = ({routes, title}) => {
  const allRoutes = [
    {path: "/#intro", title: "Home", subTitle: `Everything you need to know about ${title}.`, icon: "fa-home"},
    {path: "/#about", title: "About", subTitle: `About ${title} and our history.`, icon: "fa-info-circle"},
    {path: "/schools", title: "Schools", subTitle: `Find a ${title} school near you.`, icon: "fa-map-marker"},
    {path: "/events", title: "Events", subTitle: `Upcoming events.`, icon: "fa-calendar"},
    {path: "/#news", title: "News", subTitle: `Recent news and important information about ${title}.`, icon: "fa-newspaper-o"},
    {path: "/contact", title: "Contact Us", icon: "fa-envelope-o"},
    ...routes
  ]
  return (
    <Layout title="Site Tree" fixed>
      <main id="main" className="main-page">
        <section className="section-light-bg border-bottom-white wow fadeIn">
          <div className="container">
            <div className="section-header">
              <h2>Site Tree</h2>
              <p></p>
            </div>
          </div>
        </section>
        <section className="wow fadeIn">
          <div className="container">
            {allRoutes.map((r, index) => (
              <div key={index} className="schedule-item">
                <h3>
                  <SmartLink to={r.path}>
                    <span className={`fa ${r.icon ? r.icon : "fa-file-text-o"} mr-2`} />
                    {r.title}
                  </SmartLink>
                </h3>
                <p dangerouslySetInnerHTML={{__html: r.subTitle}} />
                <p>
                  <SmartLink to={r.path}>{window.location.origin + r.path}</SmartLink>
                </p>
              </div>
            ))}
          </div>
        </section>
      </main>
    </Layout>
  );
};


export default AppStore.connect(([state, dispatch]) => ({
  title: state.app.data.info.title,
  routes: state.app.data.routes || [],
}))(SiteTree);
