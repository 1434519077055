import React from 'react';
import {useParams} from 'react-router-dom';
import AppStore, {AppStoreActions} from '../../data';
import history from '../../lib/history';
import {getRegistration, postRegistration, removeRegistration} from '../../lib/api';
import SmartLink from '../components/SmartLink';
import Form from './Form';
import Success from './Success';
import RegistrationLayout from './RegistrationLayout';
import {idFromString} from '../../lib/utils';

export const FORM_VOCABULARY = {
  simple: {
      title: "Signup",
      past: "signed up"
  },
  advanced: {
      title: "Registration",
      past: "registered"
  },
}

const Registration = (props) => {
  const {events, registrationState, onLoadEventDetails} = props;
  let { eventId, userId = '' } = useParams();
  const newProps = {...props};
  newProps.params = {eventId, userId};
  newProps.event = events.map((e) => ({id: idFromString(e.title), ...e})).find((e) => e.id === eventId && e.registration);
  newProps.isClosed = false;
  newProps.eventCount = 999;

  React.useEffect(() => {
    if(newProps.event) {
      onLoadEventDetails(newProps.event.id, userId);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [userId]);

  // event not found
  newProps.title = "Event Form Not Found...";
  newProps.subTitle = "";
  newProps.content = <p className="text-center">The event "{eventId}" does not exist. <br /> Please try finding the event again through our <SmartLink to="/events" >events</SmartLink> page.</p>;
  let Component = () => <RegistrationLayout {...newProps} />;

  if(newProps.event) {
    newProps.vocabulary = FORM_VOCABULARY[newProps.event.registration.type || "simple"];
    // event exists woohoo!!
    newProps.eventCount = registrationState.eventCount == null ? newProps.event.registration.max : registrationState.eventCount;
    if(!userId && newProps.event && newProps.event.registration) {
      Component = Form;
    }
    else if(userId && userId === registrationState.data.id) {
      Component = Success;
    }
    else if(userId) {
      // user not found
      newProps.title = `User ${newProps.vocabulary.title} Not Found...`;
      newProps.subTitle = "";
      newProps.content = <p className="text-center">The user {newProps.vocabulary.title.toLowerCase()} id ({userId}) for the event "{eventId}" does not exist. <br /> Please make sure you have the correct URL and try again.</p>;
    }
  }
  return (
    <Component key="registration" {...newProps} />
  );
};

export default AppStore.connect(([state, dispatch]) => ({
  events: state.app.data.events,
  registrationState: state.registration,
  dispatch,
  phone: state.app.data.info.phone,
  email: state.app.data.info.email,
  onRemove: (eventId, userId) => {removeRegistration(eventId, userId, dispatch)},
  onEdit: (eventId) => {history.push(`/registration/${eventId}`)},
  onSubmit: (eventId, data) => postRegistration(eventId, data, dispatch),
  onChange: (e) => {
    const key = e.target.name;
    const value = e.target.type === "checkbox" ? e.target.checked : e.target.value
    dispatch({type: AppStoreActions.registration.update, key, value});
  },
  onReset: (e) => {
    dispatch({type: AppStoreActions.registration.reset});
  },
  onLoadEventDetails: (eventId, userId) => getRegistration(eventId, userId, dispatch)
}))(Registration);
