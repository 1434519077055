import React from 'react';
import SmartLink from './SmartLink';
import AppStore from '../../data';

const Header = ({title, shortTitle, fixed}) => (
  <header id="header" className={fixed ? "header-fixed" : ""}>
    <div className="container">
      <div id="logo" className="pull-left">
        <h1>
          <SmartLink to="/#intro">
            <img className="logo" alt={title} src="/img/fist-logo.png" width="50" height="50" />
            <strong className="d-none d-lg-inline d-xl-inline">{title}</strong>
            <strong className="d-lg-none d-xl-none">{shortTitle}</strong>
          </SmartLink>
        </h1>
      </div>

      <nav id="nav-menu-container">
        <ul className="nav-menu">
          <li><SmartLink exact to="/#intro">Home</SmartLink></li>
          <li><SmartLink exact to="/#about">About</SmartLink></li>
          <li><SmartLink to="/schools">Schools</SmartLink></li>
          <li><SmartLink to="/events">Events</SmartLink></li>
          <li><SmartLink exact to="/#news">News</SmartLink></li>
          <li><SmartLink to="/class-info/format">Class Info</SmartLink></li>
          <li className="buy-tickets"><SmartLink exact to="/contact">Contact Us</SmartLink></li>
        </ul>
      </nav>
    </div>
  </header>
);

export default AppStore.connect(([state, dispatch]) => ({
  title: state.app.data.info.title,
  shortTitle: state.app.data.info.shortTitle
}))(Header);
