import React from 'react';
import SmartLink from './SmartLink';
import AppStore, {AppStoreActions} from '../../data';
import {userLogout, save} from '../../lib/api';

const Footer = ({title, footerContent, phone, email, adminState, onAdminSave, onAdminReset, onAdminLogout}) => (
  <React.Fragment>
    <footer id="footer">
      <div className="footer-top">
        <div className="container">
          <div className="row">
            <div className="col-lg-6 col-md-6 footer-info">
              <h4>{title}</h4>
              <p>{footerContent}</p>
            </div>
            <div className="col-lg-3 col-md-6 footer-links">
              <h4>Useful Linkss</h4>
              <ul>
                <li><i className="fa fa-angle-right" /> <SmartLink to="/#top">Home</SmartLink></li>
                <li><i className="fa fa-angle-right" /> <SmartLink to="/#about">About us</SmartLink></li>
                <li><i className="fa fa-angle-right" /> <SmartLink to="/schools">Schools</SmartLink></li>
                <li><i className="fa fa-angle-right" /> <SmartLink to="/events">Events</SmartLink></li>
                <li><i className="fa fa-angle-right" /> <SmartLink to="/class-info/format">Class Info</SmartLink></li>
                <li><i className="fa fa-angle-right" /> <SmartLink to="/contact">Contact Us</SmartLink></li>
                <li><i className="fa fa-angle-right" /> <SmartLink to="/site-tree">Site Tree</SmartLink></li>
              </ul>
            </div>
            <div id="contact-us" className="col-lg-3 col-md-6 footer-contact">
              <h4>Contact Us</h4>
              <p>
                <strong>Address:</strong> <br />
                8113 SE 13th<br />
                Portland, OR 97202<br />
                United States <br /><br />
                <strong>Phone:</strong> <a href={`tel:${phone.replace(/[^0-9]/gi,'')}`}>{phone}</a><br />
                <strong>Email:</strong> <a href={`mailto:${email}`}>{email}</a><br />
              </p>
              <div className="social-links">
                <a href="https://www.facebook.com/kimstkdportland/" className="facebook" target="_blank" rel="noopener noreferrer"><i className="fa fa-facebook"></i></a>
                <a href="https://www.instagram.com/kimstkdportland/" className="instagram" target="_blank" rel="noopener noreferrer"><i className="fa fa-instagram"></i></a>
                <a href="https://www.youtube.com/channel/UCG9aWIze9YQqcbJuRlDlzFQ" className="youtube" target="_blank" rel="noopener noreferrer"><i className="fa fa-youtube"></i></a>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="container">
        <div className="copyright">
          &copy; Copyright <strong>{title}</strong>. All Rights Reserved
        </div>
        <div className="credits" style={{display: "none"}}>
          Designed by <a href="https://bootstrapmade.com/">BootstrapMade</a>
        </div>
      </div>
    </footer>
    <a href="#top" className="back-to-top"><i className="fa fa-angle-up"></i></a>
    {adminState.data.isLoggedIn && (
      <div id="admin-footer">
        <div className={`menu ${adminState.data.hasUnsavedChanges ? "bg-warning" : ""}`}>
            {window.location.pathname !== "/admin" &&
              <SmartLink
                to={window.location.pathname.indexOf("/admin") === -1 ? window.APP_LAST_ADMIN_PATH || "/admin" : "/admin"}
                className="btn btn-default btn-small mr-3"
              >
                {(window.location.pathname.indexOf("/admin") === -1 ? "Back to Admin" : "Admin Home")}
              </SmartLink>
            }
            {adminState.data.hasUnsavedChanges ? (
              <React.Fragment>
                <button
                  type="form-control"
                  className="btn btn-small btn-primary"
                  onClick={onAdminSave}
                >
                  Save
                </button>
                <button
                  type="form-control"
                  className="btn btn-small btn-default"
                  onClick={onAdminReset}
                >
                  Cancel
                </button>
              </React.Fragment>
            ) : (
              <React.Fragment>
                <button
                  type="form-control"
                  className="btn btn-small btn-default"
                  onClick={onAdminLogout}
                >
                  Logout
                </button>
              </React.Fragment>
            )}
        </div>
      </div>
    )}
  </React.Fragment>
);

export default AppStore.connect(([state, dispatch]) => ({
  title: state.app.data.info.title,
  footerContent: state.app.data.info.footerContent,
  email: state.app.data.info.email,
  phone: state.app.data.info.phone,
  adminState: state.admin,
  onAdminLogout: () => {userLogout(dispatch)},
  onAdminSave: () => {save(state.app.data, dispatch)},
  onAdminReset: () => dispatch({type: AppStoreActions.app.reset})
}))(Footer);
