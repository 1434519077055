import app from "./App";
import {traverseSet} from "../lib/utils";
const actions = {
  update: "ADMIN_ON_CHANGE",
  loading: "ADMIN_LOADING",
  loginSuccess: "ADMIN_LOGIN_SUCCESS",
  saveSuccess: "ADMIN_SAVE_SUCCESS",
  loadUsers: "ADMIN_LOAD_USERS",
  updateUser: "ADMIN_UPDATE_USER",
  error: "ADMIN_ERROR",
  reset: "ADMIN_RESET",
};

const initialState = {
  data: {
    name: null,
    email: null,
    permissions: null,
    isLoggedIn: false,
    hasUnsavedChanges: false
  },
  users: [],
  isLoading: false,
  success: false,
  error: false,
};

export default {
  actions: actions,
  reducer: (state = {...initialState}, action) => {
    let newState;
    switch(action.type) {
      case actions.update:
        newState = {...state};
        newState.data = {...newState.data, [action.key]: action.value};
        return newState;

      case actions.loading:
        return {...state, isLoading: true, error: false, success: false};

      case actions.loginSuccess:
        return {
          ...state,
          data: {...state.data, isLoggedIn: true, name: action.name, email: action.email, permissions: action.permissions},
          isLoading: false,
          error: false,
          success: true
        };

      case actions.saveSuccess:
        window.APP_HAS_UNSAVED_CHANGES = false;
        return {
          ...state,
          data: {...state.data, hasUnsavedChanges: false},
          isLoading: false,
          error: false,
          success: true
        };

      case actions.error:
        return {...state, isLoading: false, error: true, success: false};

      case actions.reset:
        return {...state, data: {...initialState.data}};

      case actions.loadUsers:
        return {...state, users: action.users, isLoading: false, error: true, success: false};

      case actions.updateUser:
        newState = {...state};
        newState = traverseSet(newState, action.key, action.value)
        return newState;

      case app.actions.update:
        window.APP_HAS_UNSAVED_CHANGES = true;
        return {...state, data: {...state.data, hasUnsavedChanges: true}};
      case app.actions.reset:
        window.APP_HAS_UNSAVED_CHANGES = false;
        return {...state, data: {...state.data, hasUnsavedChanges: false}};
      default:
        return state;
    }
  },
}

window.APP_HAS_UNSAVED_CHANGES = false;
window.onbeforeunload = function() {
  if(window.APP_HAS_UNSAVED_CHANGES) {
    return "Do you really want to leave without saving your changes?";
  }
};
