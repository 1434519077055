import {
  createContextReducer,
  combineReducers,
  reduxDevTools
} from 'react-context-reducer';
import app from "./App";
import registration from "./Registration";
import admin from "./Admin";

export const AppStoreActions = {
  app: app.actions,
  registration: registration.actions,
  admin: admin.actions
}

// Create the context store and export it.
export default createContextReducer(
  "AppStore", // store name
  combineReducers({
    app: app.reducer,
    admin: admin.reducer,
    registration: registration.reducer
  }),
  [reduxDevTools()] // middlewares
);
