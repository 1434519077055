import React from 'react';
import Layout from '../components/Layout';

const Header = ({title, subTitle, content}) => (
  <section className="section-light-bg border-bottom-white wow fadeIn">
    <div className="container">
      <div className="section-header">
        <h2>{title}</h2>
        <p>{subTitle}</p>
      </div>
      {content}
    </div>
  </section>);

export default (props) => {
  document.title = props.title;
  return (
    <Layout key="registration" title={props.title} fixed>
      <div className={props.registrationState.isLoading ? "loading" : ""}>
        <div className="spinner" />
        <main id="main" className="main-page">
          <Header {...props} />
            {props.children}
        </main>
      </div>
    </Layout>
  );
}
