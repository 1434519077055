import moment from 'moment';
let $ = window.jQuery;

export const debounce = (func, wait, immediate) => {
	var timeout;
	return function() {
		var context = this, args = arguments;
		var later = function() {
			timeout = null;
			if (!immediate) func.apply(context, args);
		};
		var callNow = immediate && !timeout;
		clearTimeout(timeout);
		timeout = setTimeout(later, wait);
		if (callNow) func.apply(context, args);
	};
};

export const hasPermission = (userData, check) => {
  if(userData.permissions.indexOf("admin") !== -1) {
    return true;
  }
  return userData.permissions.indexOf(check) !== -1;
}
export const basename = (path) => (path.substring(path.lastIndexOf("/")).replace("/",""));
export const getMonth = (date) => (parseInt(date.split("/")[0],10)-1);
export const getYear = (date) => (parseInt(date.split("/")[2],10));
export const strToTime = (t) => parseInt(t.replace(":", "."), 10);
export const mapNumber = ( x,  in_min,  in_max,  out_min,  out_max) => (x - in_min) * (out_max - out_min) / (in_max - in_min) + out_min;
export const idFromString = (title) => title.replace(/\W/g,'-').replace(/-+/g,"-").toLowerCase();
export const formatTime = (m) => {
  const parts = m.toString().split(':');
  parts[0] = parseInt(parts[0], 10);
  let ret;
  if(parts.length>1) {
    ret = parts[0] > 12 ? parts[0]-12 + ":" + (parts[1]) : parts[0] + ":" + (parts[1]);
  }
  else {
    ret = parts[0] > 12 ? parts[0]-12 : parts[0];
  }
  return ret + (parts[0] > 11 ? 'p' : 'a')
}
export const paragraphDate = (date, short = false) => {
  const d = moment(date.split(" ")[0]);
  if(!short) {
    return d.format("MMMM Do, YYYY");
  }
  return d.format("MMMM Do");
}
export const titleCaseKey = (text) => {
  var result = text.replace( /([A-Z])/g, " $1" );
  return result.charAt(0).toUpperCase() + result.slice(1);
}
export const titleToCamelCase = (text) => {
  var result = text.replace(" ", "" );
  return result.charAt(0).toLowerCase() + result.slice(1);
}

export const copyToClipboard = (text) => {
  const el = document.createElement('textarea');
  el.style.position = "absolute";
  el.style.left = -1000;
  el.value = text;
  document.body.appendChild(el);
  el.select();
  document.execCommand('copy');
  document.body.removeChild(el);
}

export const scrollTo = (target) => {
  let top_space = 0;
  if ($('#header').length) {
    top_space = $('#header').outerHeight();

    if( ! $('#header').hasClass('header-fixed') ) {
      top_space = top_space - 20;
    }
  }

  $('html, body').animate({
    scrollTop: $(target).offset().top - (top_space + 40)
  }, 1000, 'easeInOutExpo');
  $(target).focus();
}

export const validateField = (e) => {
  const elm = e.target;
  if(elm.required || elm.dataset.validate) {
    let type = elm.dataset.type;
    if(elm.type === "checkbox") type = "checkbox";

		["email", "phone", "date", "checkbox"].forEach(it => {
			if(elm.name.indexOf(it) !== -1) {
				type = it;
			}
		});
    let isValid = true;
    switch(type) {
      case "checkbox":
        isValid = elm.checked;
        break;
      case "email":
        const re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
        isValid = re.test(elm.value);
        break;
      case "date":
        isValid = elm.value.split("/").length === 3;
        break;
      case "phone":
        isValid = elm.value.replace(/[^0-9]+/gi, "").length >= 10;
        break;
      default:
        isValid = !!elm.value;
    }
    if(elm.required || (elm.dataset.validate && elm.value)) {
      if(isValid) {
        elm.classList.remove("is-invalid");
        elm.classList.add("is-valid");
      }
      else {
        elm.classList.remove("is-valid");
        elm.classList.add("is-invalid");
        return false;
      }
    }
    else {
      elm.classList.remove("is-invalid");
      elm.classList.remove("is-valid");
    }
  }
  return true;
}

export const validateForm = (form) => {
  let ret = true;
  let first_error = null;
  for(let i = 0; i < form.elements.length; i++) {
    const elm = form.elements[i];
    if(!validateField({target: elm})) {
      console.log(`Invalid field value "${elm.name}":`, elm.value);
      ret = false;
      first_error = !first_error ? elm.id : first_error;
    }
  };
  if(first_error) {
    // scroll to field
    scrollTo($(`#${first_error}`));
  }
  else {
    // scroll to top
    $('html, body').animate({
      scrollTop: 0
    }, 1000, 'easeInOutExpo');
  }

  return ret;
}

export const getFinalData = (data, form) => {
  const finalData = {};
  for(let i = 0; i < form.elements.length; i++) {
    const elm = form.elements[i];
    const key = elm.name;
    if(data.hasOwnProperty(key)) {
      finalData[key] = data[key];
    }
    else if(key) {
      console.log("Unknown data key!", key);
    }
  }
  return finalData;
};

export const resetFormValidation = (form) => {
  const finalData = {};
  for(let i = 0; i < form.elements.length; i++) {
    const elm = form.elements[i];
    elm.classList.remove("is-invalid");
    elm.classList.remove("is-valid");
  }
  return finalData;
};


export const traverseSet = (data, keys, value) => {
  if(!Array.isArray(keys)) {
    keys = keys.split('.');
  }
  const key = !isNaN(keys[0]) ? parseInt(keys[0],10) : keys[0];
  const newKeys = [...keys];
  let newData = data;
  newKeys.shift();
  if(Array.isArray(data)) {
    newData = [...data];
  }
  else if(typeof data === "object") {
    newData = {...data};
  }
  if(newKeys.length === 0) {
    newData[key] = value;
    return newData;
  }
  else {
    newData[key] = traverseSet(newData[key], newKeys, value);
    return newData;
  }
}

export const traverseGet = (data, keys) => {
  if(!Array.isArray(keys)) {
    keys = keys.split('.');
  }
  const key = !isNaN(keys[0]) ? parseInt(keys[0],10) : keys[0];
  const newKeys = [...keys];

  if(newKeys.length === 0) {
    return data[key];
  }
  else {
    return  traverseSet(data[key], newKeys);
  }
}


export const getEmptySchema = (schema) => {
  if(Array.isArray(schema)) {
    return []
  }
  else if(typeof schema === "object") {
    const ret = {};
    Object.keys(schema).forEach((key) => {
      ret[key] = getEmptySchema(schema[key]);
    });
    return ret;
  }
  else if(typeof schema === "string") {
    let parts = schema.split(":");
    if(parts[0] === "select") {
      parts = parts[1].split(",")[0].split("=");
      if(isNaN(parseInt(parts[0], 10)))  {
        return "";
      }
      else {
        return parseInt(parts[0], 10);
      }
    }
    return "";
  }
  else {
      return schema;
  }
}

export const getObjectTitleFromData = (schema, data) => {
  const output = []
  if(Array.isArray(schema)) {
    if(data) {
      //console.log("array", schema[0], getObjectTitleFromData(schema[0], data[0]));
      output.push(data.map((d) => getObjectTitleFromData(schema[0], d)).filter((t) => !!t).join(", "));
    }
  }
  else if(typeof schema === "object") {
    Object.keys(schema).forEach((key) => {
      const subSchema = schema[key];
      const subData = data[key];
      output.push(getObjectTitleFromData(subSchema, subData));
    });
  }
  else if(typeof schema === "string") {
    const parts = schema.split(":");
    if(parts[0] === "select") {
      const options = parts[1].split(",").map((o) => {
        const p = o.split("=")
        return [isNaN(p[0]) ? p[0] : parseInt(p[0], 10), p[1]];
      });
      const found = options.find((o) => o[0] === data);
      if(found)  {
        output.push(found[1] || titleCaseKey(found[0]));
      }
      else {
        //output.push("?");
      }
    }
    else if(parts[0] === "password") {
      //output.push("*");
    }
    else {
      output.push(data);
    }
  }
  else {
    output.push(data);
  }
  const ret = output.filter((t) => !!t)
  if(ret.length) {
    return ret.join(", ");
  }
  return undefined;
}
