import React from 'react';
import {hasPermission} from '../../lib/utils';
import AppStore from '../../data';
import {useParams} from 'react-router-dom';
import Login from './Login';
import Error from '../Error';
import EditWebsite from './EditWebsite';
import ViewRegistration from './ViewRegistration';
import Home from './Home';
import ManageUsers from './ManageUsers';
import Profile from './Profile';

const Admin = ({state, dispatch}) => {
  const params = useParams();
  const newProps = {state, dispatch, params};
  window.APP_LAST_ADMIN_PATH = window.location.pathname;
  if(!state.admin.data.isLoggedIn) {
    return <Login {...newProps} />;
  }
  else if(params.section === "edit" && hasPermission(state.admin.data, "edit")) {
    return <EditWebsite {...newProps} />;
  }
  else if(params.section === "registration" && hasPermission(state.admin.data, "admin")) {
    return <ViewRegistration {...newProps} />;
  }
  else if(params.section === "users" && hasPermission(state.admin.data, "admin")) {
    return <ManageUsers {...newProps} />;
  }
  else if(params.section === "profile") {
    return <Profile {...newProps} />;
  }
  else if(!params.section) {
    window.APP_LAST_ADMIN_PATH = "";
    return <Home {...newProps} />;
  }
  else {
    window.APP_LAST_ADMIN_PATH = "";
    return <Error />
  }
};

export default AppStore.connect(([state, dispatch]) => ({
  state,
  dispatch,
}))(Admin);
