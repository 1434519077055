import React from 'react';
import SmartLink from '../components/SmartLink';

export default ({history}) => {
  return (
    <section id="history">
      <div className="container">
        <div className="section-header">
          <h2>History</h2>
        </div>
        <div className="row">
          <div className="col-lg-12 timeline">
            {history.map((h, index) => (
              <div key={index} className="row">
                <div className="col-2 date">
                  <p>
                  {!h.image && <span className="dot" />}
                  {h.image && <span className="image" style={{backgroundImage: `url(${h.image})`}} />}
                  </p>
                </div>
                <div className="col-10 description">
                  <div className="date"><h3>{h.date}</h3></div>
                  <h3 className="title">{h.link ? <SmartLink to={h.link}>{h.title}</SmartLink> : h.title}</h3>
                  <p className="content" dangerouslySetInnerHTML={{__html: h.content}} />
                </div>
              </div>
            ))}
          </div>
        </div>
      </div>
    </section>
  );
};
