import React from 'react';
import Layout from '../components/Layout';
import SmartLink from '../components/SmartLink';
import {hasPermission} from '../../lib/utils';

export default ({state, dispatch}) => {
  const sections = [
    {key: "profile", title: "Profile", permissions: false}, // anyone with account can change pw.
    {key: "edit", title: "Edit Website", permissions: "edit"},
    {key: "registration", title: "Download Event Registration", permissions: "admin"},
    {key: "users", title: "Manage Users", permissions: "admin"},
  ].filter((s) => {
    return !!s.permissions ? hasPermission(state.admin.data, s.permissions) : true;
  });

  return (
    <Layout title="Admin Home" fixed>
      <main id="main" className="main-page">
        <section className="section-light-bg border-bottom-white wow fadeIn">
          <div className="container">
            <div className="section-header">
              <h2>Admin Home</h2>
              <p>Please select what you would like to do from the options below.</p>
            </div>
            <div className="text-center">{sections.length > 0 &&
              sections.map((s) => (
                  <div key={s.key}>
                    <SmartLink
                      to={`/admin/${s.key}`}
                      className="btn mb-1 w-50 btn-primary"
                    >
                      {s.title}
                    </SmartLink>
                  </div>
              ))}
            </div>
          </div>
        </section>
      </main>
    </Layout>
  );
};
