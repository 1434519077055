import React from 'react';
import Layout from './components/Layout';

export default ({code = 404}) => {
  const msg = {};
  switch(code) {
    case 500:
      msg.title = "500 - Internal Server Error";
      msg.content = "There was a problem with your request. Please try again later.";
      break;
    case 404:
      msg.title = "404 - Not Found";
      msg.content = "The page you were looking for does not exist.";
      break;
    default:
      msg.title = "Unknown Error";
      msg.content = "The app has encountered an unknown problem. If the issue continues please contact your system admin.";
  }
  return (
    <Layout title={msg.title} fixed>
      <main id="main" className="main-page">
        <section className="section-light-bg border-bottom-white wow fadeIn">
          <div className="container">
            <div className="section-header">
              <h2>{msg.title}</h2>
              <p>{msg.content}</p>
            </div>
          </div>
        </section>
      </main>
    </Layout>
  );
};
