import React from 'react';
import Layout from '../components/Layout';
import {getFinalData, validateForm, validateField, resetFormValidation} from '../../lib/utils';
import {checkLogin, userLogin} from '../../lib/api';

export default ({state, dispatch}) => {
  const formRef = React.useRef();
  const [formData, setFormData] = React.useState({email: "", password: ""});
  const onChange = (e) => {setFormData({...formData, [e.target.name]: e.target.value})};
  React.useEffect(() => {
    checkLogin(dispatch);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  },[]);
  return (
    <Layout title="Admin Login" fixed>
      <main id="main" className="main-page">
        <section className="section-light-bg border-bottom-white wow fadeIn">
          <div className="container">
            <div className="section-header">
              <h2>Admin Login</h2>
              <p>Please login with your email and password below.</p>
            </div>
          </div>
        </section>
        <section className="wow fadeIn">
          <div className="container">
            <form ref={formRef} onSubmit={(e) => e.preventDefault()} noValidate>
              <div className="form-group">
                <label className="required" htmlFor="email">Email</label>
                <input className="form-control" required="required" type="input" id="email" name="email" data-type="email" onChange={onChange} onBlur={validateField} value={formData.email} />
              </div>
              <div className="form-group">
                <label className="required" htmlFor="password">Password</label>
                <input className="form-control" required="required" type="password" id="password" name="password" onChange={onChange} onBlur={validateField} value={formData.password} />
              </div>
              <button
                type="form-control"
                className="btn btn-primary"
                onClick={() => {
                  if(validateForm(formRef.current)) {
                    userLogin(getFinalData(formData, formRef.current), dispatch);
                  }}}
              >
                Submit
              </button>
              <button
                type="form-control"
                className="btn btn-default"
                onClick={() => {setFormData({email: "", password: ""}); resetFormValidation(formRef.current);}}
              >
                Reset
              </button>
            </form>
          </div>
        </section>
      </main>
    </Layout>
  );
};
