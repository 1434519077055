import React from 'react';
import Layout from '../components/Layout';
import SmartLink from '../components/SmartLink';
import AppStore from '../../data';
import {basename} from '../../lib/utils';

const Basic = ({route, childRoutes}) => {
  const {title, subTitle, content, files = []} = route;
  const hasLeftSide = !!files.length;
  return (
    <Layout title={title} fixed>
      <main id="main" className="main-page">
        <section className="section-light-bg border-bottom-white wow fadeIn">
          <div className="container">
            <div className="section-header">
              <h2>{title}</h2>
              <p>{subTitle}</p>
            </div>
            <p className="text-center">{childRoutes.length > 0 &&
              childRoutes.map((r) => (
                  <SmartLink
                    key={r.path}
                    to={r.path}
                    className={`btn mb-1 ${r === route ? "btn-active" : "btn-primary"}`}
                  >{r.title}</SmartLink>
              ))}
            </p>
          </div>
        </section>
        <section className="wow fadeIn">
          <div className="container">
            <div className="row">
              {hasLeftSide && <div className={"col-md-3 mb-4"}>
                <div className="row no-gutters">
                  {files.filter((f) => f.indexOf(".jpg") !== -1).map((path, i) => (
                    <div key={i} className={i === 0 ? "col-12" : "col-4"}>
                      <a href={path} target="_blank" rel="noopener noreferrer">
                        <img src={path} alt={basename(path)} className="img-fluid thumbnail" />
                      </a>
                    </div>
                  ))}
                </div>
              </div>}
              <div className={hasLeftSide ? "col-md-9" : "col-md-12"}>
              <h3>{title}</h3>
              <div dangerouslySetInnerHTML={{__html: content}} />
              </div>
            </div>
          </div>
        </section>
      </main>
    </Layout>
  );
};


export default AppStore.connect(([state, dispatch]) => {
  const route = (state.app.data.routes || []).find(r => r.path === window.location.pathname);
  const parentPath = route.path.substring(0, route.path.lastIndexOf("/"));
  const childRoutes = state.app.data.routes.filter((r) => r.path.indexOf(parentPath) !== -1);
  return {
    route,
    childRoutes,
  };
})(Basic);
