import React from 'react';
import {getEventsByMonth, getTypeInfo} from '../events/Events';
import {idFromString} from '../../lib/utils';
import SmartLink from '../components/SmartLink';
export default ({events}) => {
  const months = getEventsByMonth(events);
  return (
    <section id="events">
      <div className="container">
        <div className="section-header">
          <h2>Upcoming Events</h2>
        </div>
        <div className="row justify-content-md-center">
          <div className="col-md-9">
            {months.map((m, index) => index < 2 ? (
              m.events.map((e, index) => {
                const t = getTypeInfo(e.type);
                return (
                  <div key={index} className="schedule-item">
                    <h4 key={index}><SmartLink  to={`/events#${idFromString(e.title)}`}>{t.icon} {e.title}</SmartLink></h4>
                    <time>{e.date.split(" ")[0]}</time>
                  </div>
                );
              })
            ) : null)}
          </div>
        </div>
        <div className="ellipsis text-center">...</div>
        <p className="text-center">
          <big><SmartLink to={`/events`}>See all events</SmartLink></big>
        </p>
      </div>
    </section>
  );
};
