const actions = {
  update: "REGISTRATION_ON_CHANGE",
  loading: "REGISTRATION_LOADING",
  success: "REGISTRATION_SUCCESS",
  error: "REGISTRATION_ERROR",
  reset: "REGISTRATION_RESET",
};

const initialState = {
  data: {},
  eventCount: null,
  isLoading: false,
  success: false,
  error: false,
};

export default {
  actions: actions,
  reducer: (state = {...initialState}, action) => {
    switch(action.type) {
      case actions.update:
        const newState = {...state};
        newState.data = {...newState.data, [action.key]: action.value};
        return newState;

      case actions.loading:
        return {...state, isLoading: true, error: false, success: false};

      case actions.success:
        return {
          ...state,
          data: action.data.data ? action.data.data : {...state.data},
          eventCount: action.data.count, // always get eventCount
          isLoading: false,
          error: false,
          success: !!action.data.data
        };

      case actions.error:
        return {...state, isLoading: false, error: true, success: false};

      case actions.reset:
        return {...state, data: {}};

      default:
        return state;
    }
  },
}
