import React from 'react';

export default ({news}) => {
  return (
    <section id="news">
      <div className="container">
        <div className="section-header">
          <h2>Latest News</h2>
        </div>
        <div className="row justify-content-md-center">
          <div className="col-md-9">
              {news.map((n, index) => (
                <div key={index} className="schedule-item">
                  <h3 dangerouslySetInnerHTML={{__html: '<span class="fa fa-newspaper-o mr-2"></span>' + n.title}} />
                  <time>{n.date}</time>
                  <p dangerouslySetInnerHTML={{__html: n.content}} />
                </div>
              ))}
          </div>
        </div>
      </div>
    </section>
  );
};
