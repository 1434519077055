import React, {useState, useRef} from "react";
import {copyToClipboard} from '../../../lib/utils';


export default ({name, value, onUpload, onChange, isArrayItem}) => {
  const [progress, onProgress] = useState(false);
  const onProgressRef = useRef();
  onProgressRef.current = onProgress;
  if(!value) {
    return (
      <div className="form-control">
        <input type="file" name={name} id={name} value={value} onChange={(e) => onUpload(e, (value) => onProgressRef.current(onProgressRef))} />
      </div>
    );
  }
  if(!value && progress !== false) {
    // show progress
    return (
      <div className="form-control">
        <div className="progress">
          <div className="progress-bar" role="progressbar" style={{width: progress + "%"}} />
        </div>
      </div>
    );
  }
  const copyText = `<a href="${value}" target="_blank">**LINK TITLE GOES HERE**</a>`;
  // file is uploaded
  if(isArrayItem) {
    return (
      <React.Fragment>
        <div className="form-control">
          <a href={value} target="_blank" rel="noopener noreferrer">{value}</a>
        </div>
        <div className="input-group-append">
          <div className="input-group-text" onClick={() => copyToClipboard(copyText)}><span className="fa fa-paperclip" /></div>
        </div>
      </React.Fragment>
    );
  }
  return (
    <div className="input-group">
      <div className="form-control">
        <a href={value} target="_blank" rel="noopener noreferrer">{value}</a>
      </div>
      <div className="input-group-append">
        <div className="input-group-text" onClick={() => copyToClipboard(copyText)}><span className="fa fa-paperclip" /></div>
      </div>
      <div className="input-group-append">
        <div className="input-group-text" onClick={() => onChange(name, "")}><span className="fa fa-trash text-danger" /></div>
      </div>
    </div>
  );
}
