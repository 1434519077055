import React, {useState}from 'react';
import Layout from '../components/Layout';
import SmartLink from '../components/SmartLink';
import AddressLink from '../components/AddressLink';
import AppStore from '../../data';
import {
  strToTime,
  idFromString,
  formatTime,
  basename
} from '../../lib/utils'

const dayOrder = [1,2,3,4,5,6,0];
const dayLabel = [
  "Sunday",
  "Monday",
  "Tuesday",
  "Wednesday",
  "Thursday",
  "Friday",
  "Saturday"
];

const classTypes = {
  "first-kicks": {
    title: "First Kicks",
    content: "Ages 5-7",
    icon: <span className="fa fa-child" />
  },
  "new-students": {
    title: "New Students",
    content: "White belts",
    icon: <span className="fa fa-star" />
  },
  "color-belts": {
    title: "Color Belts",
    content: "White belts and above",
    icon: <span className="fa fa-male" />
  },
  "youths": {
    title: "Youth",
    content: "Ages 7-18",
    icon: <span className="fa fa-male" />
  },
  "adults": {
    title: "Adults",
    content: "Ages 18+",
    icon: <span className="fa fa-user" />
  },
  "families": {
    title: "Families",
    content: "Family Friendly, Ages 7+",
    icon: <span className="fa fa-users" />
  },
  "advanced": {
    title: "Advanced",
    content: "Brown belts and above",
    icon: <span className="fa fa-id-card" />
  },
  "online": {
    title: "Online",
    content: "Live at home workout",
    icon: <span className="fa fa-video-camera" />
  }
};
const classTypeKeys = Object.keys(classTypes).map((key) => ({...classTypes[key], key: key}))
const getClassType = (t) => classTypes[t] || {};
const getDayLabel = (d, short = true) => short ? (dayLabel[d] || "").substring(0,3) : (dayLabel[d] || "");

const getHourBlocks = (classTimes = []) => {
  const hours = [];
  classTimes.forEach((c) => {
    if(!c.startTime) return;
    const s = Math.floor(strToTime(c.startTime));
    if(hours.includes(s)) return;
    hours.push(s);
  });
  hours.sort();
  return hours;
};

const getClassByDayHour = (classTimes = [], d, h) => {
  let found = null;
  classTimes.forEach((c) => {
    if(!c.startTime) return;
    if(!c.days.includes(d)) return;
    const s = Math.floor(strToTime(c.startTime));
    if(s === h) found = c;
  });
  return found;
};

const School = ({expanded = false, school, first = false}) => {
  const id = idFromString(school.title);
  return (
    <section id={id} className={`schools-details ${expanded ? 'active' : ''}`}>
      <div className="container">
        <div>
          <div className="row school">
            <div className="col-md-4">
              {expanded && <React.Fragment>
                <div className="row no-gutters">
                  {school.files.filter((f) => f.indexOf(".jpg") !== -1).map((img, i) => (
                    <div key={i} className={i === 0 ? "col-12" : "col-4"}>
                      <a href={img} target="_blank" rel="noopener noreferrer">
                        <img src={img} alt={basename(img)} className="img-fluid thumbnail" />
                      </a>
                    </div>
                  ))}
                </div>
                {school.address && <div className="row">
                  <div className="col-12">
                    <iframe
                      title={school.title + " Map"}
                      className="map-venue"
                      width="100%"
                      height="175"
                      src={`https://maps.google.com/maps?width=100%&height=150&hl=en&q=${encodeURIComponent(school.address.replace("\n",", "))}+(${encodeURIComponent(school.title)})&ie=UTF8&t=&z=15&iwloc=B&output=embed`}
                      frameBorder="0"
                      scrolling="no"
                      marginHeight="0"
                      marginWidth="0"
                      allowFullScreen
                    />
                  </div>
                </div>}
              </React.Fragment>}
            </div>
            <div className="col-md-8">
              <div className="details">
                <h2><SmartLink to={`/schools#${id}`}>{school.title}</SmartLink></h2>
                  {school.address && <p>
                    <AddressLink address={school.address} />
                  </p>}
                  {expanded && <React.Fragment>
                    <p dangerouslySetInnerHTML={{__html: school.content}}/>

                    {!!school.instructors.length &&
                      <React.Fragment>
                        <strong>Instructors:</strong>
                        <p dangerouslySetInnerHTML={{__html: school.instructors.join(", ")}} />
                      </React.Fragment>
                    }
                    <br/>
                    {!!school.classTimes.length && <ClassTimes school={school} />}
                  </React.Fragment>}
                  {!expanded && <SmartLink to={`/schools#${id}`} className="btn btn-small btn-primary">Show School Details</SmartLink>}
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

const ClassTimes = ({school}) => {
  return (
    <div className="week">
      <table>
        <thead>
          <tr>
            <th>{" "}</th>
            {dayOrder.map((d) => <th key={d}>{getDayLabel(d)}</th>)}
          </tr>
        </thead>
        <tbody>
          <tr>
            <td>{getHourBlocks(school.classTimes).map((h) => <div key={h}>{formatTime(h)}</div>)}</td>
            {dayOrder.map((d) => (
              <td key={d}>
                {getHourBlocks(school.classTimes).map((h) => {
                  const c = getClassByDayHour(school.classTimes, d, h);
                  return (
                    <div key={h}>
                      {c ? (
                        <span className="classinfo">
                          {getClassType(c.type).icon}
                          <span className="details">{formatTime(c.startTime)} - {formatTime(c.endTime)}</span>
                        </span>
                      ) : <React.Fragment>&nbsp;</React.Fragment>}
                    </div>
                  );}
                )}
              </td>)
            )}
          </tr>
        </tbody>
      </table>
      <div className="legend">{classTypeKeys.filter((t) =>
        school.classTimes.map(c => c.type).includes(t.key)
      ).map((t) => (
        <span key={t.key}>{t.icon} <strong>{t.title}</strong> ({t.content})</span>
      ))}</div>
    </div>
  );
}

const filters = [
  {
    key: "portland",
    title: "Portland",
    filter: (school) => school.address.toLowerCase().indexOf("portland") !== -1,
  },
  {
    key: "outlying",
    title: "Outlying Schools",
    filter: (school) => school.address.toLowerCase().indexOf("portland") === -1,
  },
  {
    key: "all",
    title: "All Schools",
    filter: () => true,
  },
];

const Schools = ({schools,location}) => {
  const [filterBy, setFilterBy] = useState("all");
  const filter = filters.find((f) => f.key === filterBy).filter;
  const schoolsFiltered = schools.filter(filter);
  const active = [location.hash ? location.hash.replace("#", '') : idFromString(schoolsFiltered[0].title)];//[active, setActive] = useState([location.hash ? location.hash.replace("#", '') : idFromString(schoolsFiltered[0].title)]);
  return (
    <Layout title="Schools" fixed>
      <main id="main" className="main-page">
        <section className={`section-light-bg border-bottom-white schools-details wow fadeIn`}>
          <div className="container">
            <div className="section-header">
              <h2>Locations</h2>
              <p>Find a school near you.</p>
            </div>
          </div>
          <ul className="nav nav-tabs" role="tablist">
            {filters.map((f) => (
              <li key={f.key} className="nav-item">
                <SmartLink
                  to={'/schools'}
                  className={`nav-link ${f.key === filterBy ? "active" : ""}`}
                  onClick={() => setFilterBy(f.key)}
                >
                  {f.title} ({schools.filter(f.filter).length})
                </SmartLink>
              </li>
            ))}
          </ul>
        </section>

        <div key={filterBy} className="wow fadeIn">
          {schoolsFiltered.map((school, index) => (
            <School
              key={index}
              school={school}
              first={index === 0}
              expanded={active.includes(idFromString(school.title))}
            />
          ))}
        </div>
      </main>
    </Layout>
  );
}

export default AppStore.connect(([state, dispatch]) => ({
  schools: state.app.data.schools,
}))(Schools);
