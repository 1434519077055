import React from 'react';
import history from '../lib/history';
import AppStore from '../data';
import {Router, Switch, Route} from 'react-router-dom';
import Admin from './admin/Admin';
import Home from './home/Home';
import Error from './Error';
import Basic from "./basic/Basic";
import Schools from './schools/Schools';
import Events from './events/Events';
import SiteTree from './sitetree/SiteTree';
import Registration from './registration/Registration';
import Contact from './contact/Contact';

const Routes = ({routes = [], isReady}) => {
  if(!isReady) {
    return (
      <React.Fragment>
        <header id="header" className={"header-fixed"}>
          <div className="container"><nav>{" "}</nav></div>
        </header>
        <div id="main" className="main-page">
          <section>
            <div className="container text-center">
              Loading...
            </div>
          </section>
        </div>
        <footer id="footer">
          <div className="footer-top">
            <div className="container">

            </div>
          </div>
          <div className="container">
            <div className="copyright">
              &copy; Copyright - All Rights Reserved
            </div>
            <div className="credits" style={{display: "none"}}>
              Designed by <a href="https://bootstrapmade.com/">BootstrapMade</a>
            </div>
          </div>
        </footer>
      </React.Fragment>
  );
  }
  return (
    <Router history={history}>
      <Switch>
        <Route path="/admin/:section?/:sectionKey?" component={Admin} />
        <Route path="/schools" component={Schools} />
        <Route path="/events" component={Events} />
        <Route path="/registration/:eventId?/:userId?" component={Registration} />
        <Route path="/site-tree" component={SiteTree} />
        <Route path="/contact/:campaignId?" component={Contact} />
        <Route exact path="/" component={Home} />
        {routes.map((r) =>
            <Route key={r.path} path={r.path} exact={r.exact || true} component={Basic} />
        )}
        <Route exact path="/*" component={Error} />
      </Switch>
    </Router>
  );
}
export default AppStore.connect(([state, dispatch]) => ({
  routes: state.app.data.routes || [],
  isReady: state.app.isReady
}))(Routes);

//export default Routes;
