import React from 'react';
import Layout from '../components/Layout';
import Control from './components/Control';
import { getUsers, createUser, updateUser, deleteUser } from '../../lib/api';
import {AppStoreActions} from '../../data';
import history from "../../lib/history";
import {getEmptySchema} from "../../lib/utils";

const userSchema = {
    name: "",
    email: "email",
    password: "password",
    permissions: "select:user=User,admin=Admin,edit=Editor",
    active: 1,
  };

export default ({state, dispatch, params}) => {
  const users = state.admin.users;
  const {sectionKey} = params;
  const userIndex = parseInt(sectionKey || 0, 10);
  const formRef = React.useRef();
  const onChange = (key, value) => {
    dispatch({type: AppStoreActions.admin.updateUser, key, value});
  };
  const userData = users[userIndex] || {};

  React.useEffect(() => {
    getUsers(dispatch);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  if(!!sectionKey && users.length > 0 && !users[userIndex] && userIndex !== 0) {
    history.push(`/admin/users/`);
  }

  return (
    <Layout title="Manage Users" fixed>
      <main id="main" className="main-page">
        <section className="section-light-bg border-bottom-white wow fadeIn">
          <div className="container text-center">
            <div className="section-header">
              <h2>Manage Users</h2>
              <p>Please select a user below or create one.</p>
            </div>

            <div className={"input-group w-50 d-inline-block"}>
              <select
                className="custom-select w-100 font-weight-bold"
                value={userIndex}
                onChange={(e) => {
                  if(e.target.value === "new") {
                    const emptySchema = getEmptySchema(userSchema);
                    const newData = [...(users || []), {...emptySchema, name: "New User"}];
                    onChange("users", newData);
                    history.push(`/admin/users/${newData.length-1}`);
                  }
                  else {
                    history.push(`/admin/users/${e.target.value}`);
                  }
                }}
              >
                {users.map((o,index) => <option key={index} value={index}>{o.name}</option>)}
                <option value={"new"}>*Create a New User*</option>
              </select>
            </div>
          </div>
        </section>
        <section className="wow fadeIn">
          <div className="container">
            <form ref={formRef} onSubmit={(e) => e.preventDefault()} noValidate>
              <Control
                key={userData.id}
                shape={userSchema}
                data={userData}
                onChange={onChange}
                name={"users."+userIndex}
                title={userIndex}
              />
              <div className="mt-3">
                <button
                  type="form-control"
                  className="btn btn-primary"
                  onClick={() => {
                    if(!userData.id) {
                      createUser(userData, dispatch);
                    }
                    else {
                      updateUser(userData, dispatch);
                    }
                  }}
                >
                  Save
                </button>
                <button
                  type="form-control"
                  className="btn btn-default"
                  onClick={() => {getUsers(dispatch)}}
                >
                  Reset
                </button>
                <button
                  type="form-control"
                  className="btn btn-danger pull-right"
                  onClick={() => {deleteUser(userData, dispatch)}}
                >
                  Delete User
                </button>
              </div>
            </form>
          </div>
        </section>
      </main>
    </Layout>
  );
};
