import React from "react";
import Header from "./Header";
import Footer from "./Footer";
import ErrorBoundary from '../ErrorBoundary';

export default ({title = "Home", fixed, children}) => {
  document.title = title + " : Kim's Taekwon-Do Portland";
  return (
    <ErrorBoundary>
      <Header fixed={fixed} />
      {children}
      <Footer />
    </ErrorBoundary>
  );
}
