import React, {useState, useRef} from 'react';
import SmartLink from '../components/SmartLink';

export default ({rotators}) => {
  const timeoutRef = useRef(null);
  const [rotatorIndex, setRotatorIndex] = useState(0);
  if(timeoutRef.current) {
    clearTimeout(timeoutRef.current);
  }
  React.useEffect(() => {
    timeoutRef.current = setTimeout(() => {
      let nextIndex = rotatorIndex+1;
      if(nextIndex >= rotators.length) nextIndex = 0;
      setRotatorIndex(nextIndex)
    }, 10000);
    return () => {
      clearTimeout(timeoutRef.current);
    }
  })

  const rotator = rotators[rotatorIndex];
  return (
    <div style={{background: "#040919"}}>
      <section key={rotatorIndex} id="intro" className="wow fadeIn" style={{backgroundImage: `url(${rotator.image})`}}>
        <div className="intro-container">
          <h1 className="mb-4 pb-0" dangerouslySetInnerHTML={{__html: rotator.title}} />
          {rotator.content && <p className="mb-4 pb-0">{rotator.content}</p>}
          {rotator.link && <SmartLink to={rotator.link} className="about-btn">{rotator.linkTitle}</SmartLink>}
        </div>
        <ul className={"intro-rotators"}>
          {rotators.map((r,i) => (
            <li
              key={i}
              onClick={() => setRotatorIndex(i)}
              className={`${i===rotatorIndex ? 'active' : ''} `}
              style={{backgroundImage: `url(${r.image})`}}
            />
          ))}
        </ul>
      </section>
    </div>
  );
};
