import React from 'react';
import AppStore from './data';
import Routes from './views/Routes';

const App = (props) => {
  return (
    <AppStore.Provider>
      <Routes />
    </AppStore.Provider>
  )
};

export default App;
