import {traverseSet} from "../lib/utils";
import admin from "./Admin";
const actions = {
  update: "APP_ON_CHANGE",
  reset: "APP_ON_RESET",
};

const getInitialState = () => ({
  data: {...window.APP_DATA},
  schema: {...window.APP_DATA_SCHEMA},
  isReady: !!window.APP_DATA && !!window.APP_DATA_SCHEMA
});

export default {
  actions: actions,
  reducer: (state = getInitialState(), action) => {
    switch(action.type) {
      case actions.update:
        const newState = {...state};
        newState.data = traverseSet(newState.data, action.key, action.value)
        return newState;

      case actions.reset:
      case admin.actions.reset:
      case admin.actions.saveSuccess:
        return getInitialState();

      default:
        return state;
    }
  },
}
