import React from "react";
import {
  titleCaseKey,
  validateField,
  formatTime,
  getEmptySchema,
  getObjectTitleFromData
} from '../../../lib/utils';
import Code from "./Code";
import File from "./File";

const keyPathToTitle = (path, singular = false) => {
  const title = titleCaseKey(path.substring(path.lastIndexOf('.')+1)).trim(' ');
  if(!singular) {
    return title;
  }
  if(title.charAt(title.length-1) === "s") {
    return title.substring(0, title.length-1);
  }
  return title;
}

const Control = (props) => {
  const {shape, onChange, title, name = "", depth = 0, isArrayItem = false, onRemove, onMoveUp, onMoveDown, onUpload, simple = false, required = false} = props;
  let {data} = props
  const [expanded, setExpanded] = React.useState(!depth);
  const HeaderComponent = depth < 1 ? "h3" : "strong"
  if(Array.isArray(shape)) {
    const subSchema = shape[0];
    const onAdd = () => {
      const emptySchema = getEmptySchema(subSchema);
      const newData = [...(data || []), Array.isArray(emptySchema) ? [...emptySchema] : typeof emptySchema === "object" ? {...emptySchema} : emptySchema];
      onChange(name, newData);
    };

    return (
      <div className={simple ? "" : "form-container"}>
        {!simple && <HeaderComponent className="pl-2 pl-md-3 p-2 d-block" onClick={() => setExpanded(!expanded)}>
          <span dangerouslySetInnerHTML={{__html: title || keyPathToTitle(name)}} />
          {isArrayItem && <span className="p-1 pull-right fa fa-trash text-danger here" onClick={onRemove} />}
          <span className={`p-1 pull-right fa ${expanded ? "fa-angle-down" : "fa-angle-up"}`} />
        </HeaderComponent>}
        {expanded && (data || []).map((it, index) => {
          const subTitle = it.title || it || keyPathToTitle(title, true);
          const onRemove = (e) => {
            e.preventDefault();
            e.stopPropagation();
            if(window.confirm(`Are you sure you want to remove "${subTitle}"`)) {
              const newData = [...data];
              newData.splice(index, 1);
              onChange(name, newData);
            }
          };
          const onMoveUp = (e) => {
            e.preventDefault();
            e.stopPropagation();
            if(index <= 0) return;
            const newData = [...data];
            newData.splice(index-1, 0, newData.splice(index, 1)[0]);
            onChange(name, newData);
          };
          const onMoveDown = (e) => {
            e.preventDefault();
            e.stopPropagation();
            if(index >= data.length-1) return;
            const newData = [...data];
            newData.splice(index+1, 0, newData.splice(index, 1)[0]);
            onChange(name, newData);
          };
          return (
            <div key={name+"."+index} className={simple ? "" : `schedule-item ${index % 2 === 1 && depth < 1 ? "light-bg" : ""} p-2 p-md-3`}>
              <Control
                {...props}
                shape={subSchema}
                data={it}
                name={[name,index].filter(p => p !== undefined).join(".")}
                title={keyPathToTitle(title, true)}
                depth={depth+1}
                isArrayItem={true}
                onRemove={onRemove}
                onMoveUp={onMoveUp}
                onMoveDown={onMoveDown}
              />
            </div>
          );
        })}
        {expanded && <p className="text-center mt-2">
          <button className="btn btn-default btn-full" onClick={onAdd}>Add {keyPathToTitle(title, true)}</button>
        </p>}
    </div>
    );
  }
  else if(typeof shape === "object") {
    return (
      <div className={simple ? "" : "form-container"}>
        {!simple && <HeaderComponent className="pl-2 pl-md-3 p-2 d-block" onClick={() => setExpanded(!expanded)}>
          <span dangerouslySetInnerHTML={{__html: data.title || data.name || getObjectTitleFromData(shape, data) || keyPathToTitle(name, true)}} />
          {isArrayItem && (
            <span className="pull-right d-inline">
              <span className="p-1 fa fa-arrow-up" onClick={onMoveUp} />
              <span className="p-1 fa fa-arrow-down" onClick={onMoveDown} />
              <span className="p-1 fa fa-trash text-danger" onClick={onRemove} />
            </span>
          )}
          <span className={`p-1 pull-right fa ${expanded ? "fa-angle-down" : "fa-angle-up"}`} />
        </HeaderComponent>}
        {expanded && Object.keys(shape).map((subKey, index) => {
          return (
            <div key={name+"."+index} className={simple ? "" : `schedule-item ${index % 2 === 1 && depth < 1 ? "light-bg" : ""} p-2 p-md-3`}>
              <Control
                {...props}
                shape={shape[subKey]}
                data={data[subKey]}
                name={[name,subKey].filter(p => p !== undefined && p !== "").join(".")}
                isArrayItem={false}
                title={keyPathToTitle(subKey)}
                depth={depth+1}
              />
            </div>
          );
        })}
      </div>
    );
  }
  let component = null;
  let parts = typeof shape === "string" ? shape.split(':') : [shape];
  let shapeType = parts[0];
  const shapeExtra = parts[1];

  parts = name.split('.');
  //const keyPath = parts[0];
  const keyName = parts[parts.length-1];



  switch(shapeType) {
    case "file":
      component = (
        <File
          name={name}
          value={data}
          onUpload={onUpload}
          onChange={onChange}
          isArrayItem={isArrayItem}
        />
        );
      break;
    case "select":
      const options = shapeExtra.split(",").map((opt) => { const p = opt.split("="); return {value: p[0], title: p[1] || titleCaseKey(p[0])}; } );
      component = (
        <select
          className="custom-select"
          id={name}
          name={name}
          value={data === undefined ? "" : data}
          onChange={(e) => onChange(name, isNaN(parseInt(e.target.value, 10)) ? e.target.value : parseInt(e.target.value, 10))}
          onBlur={validateField}
          required={required}
        >
          {options.map((o) => <option key={o.value} value={o.value}>{o.title}</option>)}
        </select>
      );
    break;
    case "editor":
    case "editor-small":
      component = (
        <Code
          shape={shapeType}
          type="input"
          id={name}
          name={name}
          value={data === undefined ? "" : data}
          placeholder={title || keyPathToTitle(name)}
          onChange={onChange}
          onBlur={validateField}
        />
      );
    break;
    case "textarea":
    component = (
      <textarea
        className="form-control"
        id={name}
        name={name}
        value={data === undefined ? "" : data}
        placeholder={title || keyPathToTitle(name)}
        onChange={(e) => onChange(name, inputType === "number" ? parseInt(e.target.value, 10) : e.target.value)}
        onBlur={validateField}
        data-validate={true}
        rows="4"
        required={required}
      />
    );
    break;
    default:
      let inputType = "input";
      if(!isNaN(shapeType) && shapeType !== "") {
        inputType = "number";
        if(isNaN(data)) {
          data = "";
        }
      }
      if(shapeType === "password") {
        inputType = "password";
      }
      component = (
        <input
          className="form-control"
          type={inputType}
          id={name}
          name={name}
          value={data === undefined ? "" : data}
          placeholder={title || keyPathToTitle(name)}
          onChange={(e) => onChange(name, inputType === "number" ? parseInt(e.target.value, 10) : e.target.value)}
          onBlur={validateField}
          data-validate={true}
          required={required}
        />
      );
  }
  if(isArrayItem) {
    return (
      <div className={"input-group"}>
        {component}
        <div className="input-group-append">
          <div className="input-group-text" onClick={onMoveUp}><span className="fa fa-arrow-up" /></div>
        </div>
        <div className="input-group-append">
          <div className="input-group-text" onClick={onMoveDown}><span className="fa fa-arrow-down" /></div>
        </div>
        <div className="input-group-append">
          <div className="input-group-text" onClick={onRemove}><span className="fa fa-trash text-danger" /></div>
        </div>
      </div>
    );
  }
  else {
    let extraTitle = "";
    if(keyName.toLowerCase().indexOf("date") !== -1) {
      extraTitle += "MM/DD/YYYY HH:mm:ss";
    }
    if(keyName.toLowerCase().indexOf("time") !== -1) {
      if(data) {
        extraTitle += `24hr format: ${data} = ${formatTime(data)}`;
      }
      else {
        extraTitle += "24hr format: 17:00 = 5:00pm";
      }
    }
    return (
      <div className={"form-group"}>
        <label className={required ? "required" : ""} htmlFor={name} required={required}>{title || keyPathToTitle(name)} {extraTitle && <small>({extraTitle})</small>}</label>
        {component}
      </div>
    );
  }
}

export default Control;
