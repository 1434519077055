import AppStore, {AppStoreActions} from "../data";
import history from "./history";
import {debounce} from "./utils";
import axios from "axios";
const api = axios.create({
  baseURL: '/api/',
  timeout: 0,
});

export const getApp = (dispatch = AppStore.dispatch) => {
  dispatch({
    type: AppStoreActions.admin.loading
  });
  return api.get(`get/app`)
    .then((response) => {
      window.APP_DATA = {...response.data.data};
      window.APP_DATA_SCHEMA = {...response.data.schema};
      dispatch({
        type: AppStoreActions.app.reset,
      });
      return response;
    },
    (err) => {
      dispatch({type: AppStoreActions.admin.error});
      alert("loading app failed!");
      return Promise.reject(err);
    }
  );
}
if(!window.APP_DATA) {
  console.log("Loading app data...");
  getApp().then(() => console.log("Done!"));
}

export const postRegistration = (eventId, data, dispatch) => {
  dispatch({
    type: AppStoreActions.registration.loading
  });
  return api.post(`registration/${eventId}`, data)
    .then((response) => {
      dispatch({
        type: AppStoreActions.registration.success,
        data: response.data,
      });

      // push the returned id to the url
      const userId = response.data.data.id;
      history.push(`/registration/${eventId}/${userId}`);

      return response;
    },
    (err) => {
      dispatch({type: AppStoreActions.registration.error});
      alert("Registration Failed!");
      return Promise.reject(err);
    });
};

export const getRegistration = (eventId, userId, dispatch) => {
  dispatch({
    type: AppStoreActions.registration.loading
  });
  return api.get(`registration/${eventId}/${userId}`)
    .then((response) => {
      dispatch({
        type: AppStoreActions.registration.success,
        data: response.data,
      });
      return response;
    },
    (err) => {
      // probably nothing...
      dispatch({type: AppStoreActions.registration.error});
      return Promise.reject(err);
    }
  );
};

export const removeRegistration = (eventId, userId, dispatch) => {
  dispatch({
    type: AppStoreActions.registration.loading
  });
  return api.delete(`registration/${eventId}/${userId}`)
    .then((response) => {
      dispatch({
        type: AppStoreActions.registration.reset,
      });
      history.push(`/registration/${eventId}`);
      return response;
    },
    (err) => {
      dispatch({type: AppStoreActions.registration.error});
      alert("Canceling Registration Failed!");
      return Promise.reject(err);
    }
  );
};


export const checkLogin = (dispatch) => {
  dispatch({
    type: AppStoreActions.admin.loading
  });
  return api.get(`admin/login`)
    .then((response) => {
      if(response.data.email) {
        dispatch({
          type: AppStoreActions.admin.loginSuccess,
          email: response.data.email,
          name: response.data.name,
          permissions: response.data.permissions
        });
      }
      return response;
    },
    (err) => {
      // expected
      return Promise.reject(err);
    }
  );
};

export const userLogin = (data, dispatch) => {
  dispatch({
    type: AppStoreActions.admin.loading
  });
  const errMessage = "Login failed! Please make sure you are using the right email and password.";
  return api.post(`admin/login`, data)
    .then((response) => {
      if(response.data.email) {
        dispatch({
          type: AppStoreActions.admin.loginSuccess,
          email: response.data.email,
          name: response.data.name,
          permissions: response.data.permissions
        });
      }
      else {
        dispatch({type: AppStoreActions.admin.error});
        alert(errMessage);
      }
      return response;
    },
    (err) => {
      dispatch({type: AppStoreActions.admin.error});
      alert(errMessage);
      return Promise.reject(err);
    }
  );
};

export const userLogout = (dispatch) => {
  dispatch({
    type: AppStoreActions.admin.loading
  });
  return api.get(`admin/logout`)
    .then((response) => {
      dispatch({
        type: AppStoreActions.admin.reset,
      });
      history.push('/');
      return response;
    },
    (err) => {
      dispatch({type: AppStoreActions.admin.error});
      alert("Logout failed!");
      return Promise.reject(err);
    }
  );
};

export const save = (data, dispatch) => {
  dispatch({
    type: AppStoreActions.admin.loading
  });
  return api.post(`admin/save`, data)
    .then((response) => {
      window.APP_DATA = {...response.data.data};
      dispatch({
        type: AppStoreActions.admin.saveSuccess,
      });
      return response;
    },
    (err) => {
      dispatch({type: AppStoreActions.admin.error});
      alert("Save failed!");
      return Promise.reject(err);
    }
  );
};

export const upload = (e, dispatch, onProgress = () => {}) => {
  let formData = new FormData();
  const {name, files} = e.target;
  formData.append('file', files[0]);
  onProgress(0);
  return api.post(`admin/upload`, formData, {
    headers: { 'Content-Type': 'multipart/form-data' },
    onUploadProgress: debounce(( progressEvent ) => {
      const percent = Math.round( ( progressEvent.loaded / progressEvent.total ) * 100 );
      onProgress(percent);
    }, 65, true)
  })
    .then((response) => {
      dispatch({
        type: AppStoreActions.app.update,
        key: name,
        value: response.data.fileName,
      });
      return response;
    },
    (err) => {
      alert("Upload failed!");
      return Promise.reject(err);
    }
  );
};


export const updateProfile = (data, dispatch) => {
  dispatch({
    type: AppStoreActions.admin.loading
  });
  const errMessage = "Profile update failed! Please make sure you are using the correct password";
  return api.post(`admin/profile`, data)
    .then((response) => {
      if(response.data.email) {
        dispatch({
          type: AppStoreActions.admin.loginSuccess,
          email: response.data.email,
          name: response.data.name,
          permissions: response.data.permissions
        });
        alert("Success! Profile updated.");
      }
      else {
        dispatch({type: AppStoreActions.admin.error});
        alert(errMessage);
      }
      return response;
    },
    (err) => {
      dispatch({type: AppStoreActions.admin.error});
      alert(errMessage);
      return Promise.reject(err);
    }
  );
};

export const getUsers = (dispatch) => {
  return api.get(`admin/users`).then(
    (response) => {
      dispatch({
        type: AppStoreActions.admin.loadUsers,
        users: response.data.data
      });
    },
    (err) => {
      alert("Failed to get users!");
      return Promise.reject(err);
    });
};
export const createUser = (data, dispatch) => {
  return api.put(`admin/users`, data).then(
    (response) => {
      dispatch({
        type: AppStoreActions.admin.loadUsers,
        users: response.data.data
      });
    },
    (err) => {
      alert("Failed to create user!");
      return Promise.reject(err);
    });
};
export const updateUser = (data, dispatch) => {
  return api.post(`admin/users`, data).then(
    (response) => {
      dispatch({
        type: AppStoreActions.admin.loadUsers,
        users: response.data.data
      });
    },
    (err) => {
      alert("Failed to update user!");
      return Promise.reject(err);
    });
};
export const deleteUser = (data, dispatch) => {
  return api.delete(`admin/users`, {data: data}).then(
    (response) => {
      dispatch({
        type: AppStoreActions.admin.loadUsers,
        users: response.data.data
      });
    },
    (err) => {
      alert("Failed to delete users!");
      return Promise.reject(err);
    });
};

export const contactUs = (data) => {
  return api.post(`contact`, data).then(
    (response) => {
      // success
    },
    (err) => {
      alert("Failed to submit contact form!");
      return Promise.reject(err);
    });
};

export default api;
