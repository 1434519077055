import React from 'react';
import {NavLink} from 'react-router-dom';

export const MenuLink = (props) => {
  const newProps = {activeClassName: "menu-active", ...props};
  newProps.isActive = (match, location) => {
    if (newProps.exact && newProps.to.indexOf('#') !== -1 && newProps.to === location.pathname+location.hash) {
      return true;
    }
    else if(!newProps.exact && match) {
      return true;
    }
    return false;
  };
  newProps.key = Math.random();
  return (
    <NavLink
      {...newProps}
    />
  );
}


export default (props) => {
  const newProps = {
    className: "",
    ...props
  };
  if(props.to.indexOf("http") === 0) {
    return <a href={props.to} className={props.className} target="_blank" rel="noopener noreferrer">{props.children}</a>
  }
  newProps.className += " scrollto";
  return (
    <MenuLink {...newProps} />
  );
}
